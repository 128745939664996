.labeled-dropdown {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid $primary-open-sky-s2;
    background: #FFF;
    width: 187px;
    height: 35px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    &.labeled-dropdown__disabled {
        cursor: not-allowed;
        > .labeled-dropdown--label-arrow {
            > div:nth-of-type(1) > label {
                cursor: not-allowed;
                color: $secondary-sanfrancisco-fog-s1;
            }
            > div:nth-of-type(2) > svg > path {
                fill: $secondary-sanfrancisco-fog-s1;
            }
        }
    }
    > .labeled-dropdown--select {
        position: absolute;
        top: 33px;
        left: 0;
        width: 187px;
        box-sizing: border-box;
        @include header-level5;
    }
    &.labeled-dropdown__collapsed {
        > .labeled-dropdown--label-arrow {
            > div:nth-of-type(2) {
                > svg {
                    transform: rotateZ(0deg);
                }
            }
        }
        > .labeled-dropdown--select {
            display: none;
        }
    }
    &.labeled-dropdown__expanded {
        > .labeled-dropdown--label-arrow {
            > div:nth-of-type(2) {
                > svg {
                    transform: rotateZ(180deg) translateY(-2px);
                }
            }
        }
        > .labeled-dropdown--select {
            display: block;
        }
    }
    > .labeled-dropdown--label-arrow {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        > div {
            &:nth-of-type(1) {
                > label {
                    @include header-level5($primary-open-sky-s2);
                    padding-left: 10px;
                    cursor: pointer;
                }
            }
            &:nth-of-type(2) {
                flex-basis: 20px;
                flex-shrink: 0;
                flex-grow: 0;
                height: 15px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                padding-right: 5px;
                cursor: pointer;
                > svg {
                    > path {
                        fill: $primary-open-sky-s2;
                    }
                }
            }
        }
    }
}
