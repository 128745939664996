
$speaker-card-squish-for-height-breakpoint: 1290px;
$wide-speaker-card-gap: $speaker-card-squish-for-height-breakpoint + 1;
$reduce-vertical-padding-breakpoint: 1120px;
$two-column-min-width: 1020px;
$single-column-max-width: 1019px;
$shrink-card-images-breakpoint: 660px;
$content-panel-collapse-breakpoint: 834px;
$content-panel-expand-breakpoint: 1019px;

.landing-page-content__right-panel.conference-speaker-session-page {
    background: unset;
    > .conference-speaker-session-page--embedded-video {
        @media screen and (min-width: 835px) {
            padding: 40px 40px 80px 80px;
        }
        @media screen and (max-width: 834px) {
            padding: 40px 0;
        }
    }
    .landing-page-content__right-panel--content--body {
        @media screen and (max-width: $content-panel-collapse-breakpoint) {
            padding: 20px 0 0 0;
            flex-basis: 100%;
        }
        @media screen and (min-width: $content-panel-expand-breakpoint) {
            padding: 40px 0 40px 80px;
            flex-basis: calc(63% - 80px);
        }
        
        background: unset;
        > p {
            margin-top: 0;
            padding-right: 20px;
            @media screen and (max-width: 834px) {
                padding-right: 0;
                overflow-wrap: anywhere;
            }
        }
        > img {
            margin: 0;
            object-fit: fill;
            width: calc(100% - 80px);
            height: auto;
        }
    }
    > .landing-page-content__right-panel--content {
        > .landing-page-content__right-panel--content--side-panel {
            @media screen and (max-width: $content-panel-collapse-breakpoint) {
                flex-basis: calc(100% - 40px);
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
            @media screen and (min-width: $content-panel-expand-breakpoint) {
                flex-basis: calc(37% - 63px);
                padding-left: 23px;
                padding-right: 40px;
                padding-top: 55px;
            }
            &.landing-page-content__right-panel--content--side-panel__events-page {
                @media screen and (max-width: 834px) {
                    padding-left: 0;
                    padding-right: 0;
                    flex-basis: 100%;
                    > .events--calendar--details--card {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.speaker-session--details-card {
    background-color: white;
    padding:  30px 28px 54px 33px;
    @include thick-edge-bottom;
    @include card-shadow;
    > h4 {
        margin-top: 66px;
        margin-bottom: 10px;
    }
    > h3 {
        margin-top: 10px;
    }
    > .speaker-session--details-card--date {
        @include header-level2($primary-open-sky);
        margin-top: 0;
    }
    > .speaker-session--details-card--date.speaker-session--details-card--date__not_keynote {
        margin-top: 66px;
    }
    > .speaker-session--details-card--related {
        > h4 {
            margin-bottom: 0;
        }
        > h3 {
            margin-top: 0px;
            margin-bottom: 0;
            > a {
                @include header-level3($primary-open-sky-s2);
            }
        }
        > h5 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    > .speaker-session--details-card--more {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        > div {
            flex-basis: 52%;
            max-width: 252px;
            flex-grow: 0;
            @include link-button($primary-open-sky-s1);
            > a {
                color: $secondary-sanfrancisco-fog-t1;
                padding: 10px 50px;
            }
        }
    }
}

.conference-speaker-session-page--view-all {
    @include page-element-padding;
    display: flex;
    @media screen and (max-width: $content-panel-collapse-breakpoint) {
        flex-direction: column;
        align-items: center;
        row-gap: 2em;
    }
    @media screen and (min-width: 834px) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .conference-speaker-session-page--view-all--link-button {
        flex-basis: unset;
        max-width: unset;
        flex-grow: 0;
        @include interactive-link-button-dark-mode("conference-speaker-session-page--view-all--link-button", 50px);
        > a {
            padding: 0 50px;
        }
    }
}

.speaker-session--speaker-cards {
    @include page-element-padding;
    display: grid;
    @media screen and (min-width: 1200px) {
        column-gap: 66px;
    }
    @media screen and (min-width: $two-column-min-width) {
        grid-template-columns: 1fr 1fr;
        row-gap: 60px;
    }
    @media screen and (max-width: $single-column-max-width) {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    margin-bottom: 0;
    
    @media screen and (max-width: $speaker-card-squish-for-height-breakpoint) {
        column-gap: 20px;
    }
    @media screen and (min-width: $wide-speaker-card-gap) {
        column-gap: 66px;
    }
    
    > .speaker-session--speaker-cards--card {
        cursor: pointer;
        background-color: white;
        &.speaker-session--speaker-cards--card__left-edge {
            @include thick-edge-left;
            border-bottom: unset;
        }
        &.speaker-session--speaker-cards--card__bottom-edge {
            @include thick-edge-bottom;
            border-left: unset;
        }
        @include card-shadow;
        max-width: 600px;
        min-width: 400px;
        @media screen and (max-width: $shrink-card-images-breakpoint) {
            min-width: auto;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        @media screen and (max-width: $shrink-card-images-breakpoint) {
            flex-direction: column;
        }
        &.speaker-session--speaker-cards--card__categorized {
            height: 130px;
            &.session-card {
                /*
                 * FYI: The highly detailed and granular media queries below are necessary to account for
                 * the wild variability in the length of text in the card content (Conference session names, especially)
                 * while maintaining layout consistency with the category indicator, and defined margins / padding.
                 * It's worth noting that should this code be used for future conferences it's likely that these media queries
                 * won't apply to the new content, and will need to be adjusted. The best approach would be to normalize
                 * requirements for the length of text in the card content, or use line clamping in the card text, because
                 * this complexity makes 2 or 3 work while making the rest look like they have too much exmpty space, but
                 * at least there's no text overflowing the cards.
                 */
                @media screen and (min-width: 1462px) {
                    height: 170px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 150px;
                    }
                }
                @media screen and (min-width: 1415px) and (max-width: 1461px) {
                    height: 220px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 180px;
                    }
                }
                @media screen and (min-width: 1215px) and (max-width: 1414px) {
                    height: 240px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 190px;
                    }
                }
                @media screen and (max-width: 1214px) and (min-width: 1020px) {
                    height: 220px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 200px;
                    }
                }
                @media screen and (max-width: 1019px) {
                    max-width: unset;
                }
                @media screen and (max-width: 1019px) and (min-width: 619px) {
                    height: 175px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 150px;
                    }
                }
                @media screen and (min-width: 595px) and (max-width: 618px) {
                    height: 210px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 170px;
                    }
                }
                @media screen and (min-width: 520px) and (max-width: 594px) {
                    height: 230px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 190px;
                    }
                }
                @media screen and (min-width: 455px) and (max-width: 519px) {
                    height: 270px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 230px;
                    }
                }
                @media screen and (min-width: 390px) and (max-width: 454px) {
                    height: 300px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 270px;
                    }
                }
                @media screen and (min-width: 385px) and (max-width: 389px) {
                    height: 330px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 290px;
                    }
                }
                @media screen and (max-width: 384px) {
                    height: 400px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 370px;
                    }
                }
                > .speaker-session--speaker-cards--card--content {
                    > h3 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
            &.speaker-card {
                height: 130px;
                @media screen and (max-width: 400px) {
                    height: 166px;
                    > .speaker-session--speaker-cards--card--content {
                        height: 166px;
                    }
                }
                @media screen and (min-width: 401px) {
                    > .speaker-session--speaker-cards--card--content {
                        height: 130px;
                    }
                }
            }
            > .speaker-session--speaker-cards--card--content {
                max-width: calc(95% - 20px);
                @media screen and (max-width: $single-column-max-width) {
                    > h3 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
                @media screen and (min-width: $two-column-min-width) {
                    > h3 {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
                > .keynote-speaker-label {
                    margin-top: 10px;
                }
                > .speaker-session--speaker-cards--card--content--job-title {
                    margin-top: 0;
                    white-space: break-spaces;
                    max-width: 100%;
                }
            }
        }
        > .speaker-session--speaker-cards--card--image {
            @media screen and (min-width: $shrink-card-images-breakpoint) {
                flex-grow: 0;
                flex-shrink: 0;
                width: 200px;
                height: 200px;
            }
            @media screen and (max-width: 659px) {
                flex-grow: 1;
                flex-shrink: 1;
                max-width: 199px;
                width: auto;
                height: auto;
            }
            
            > img {
                object-fit: fill;
                width: 100%;
                height: 100%;
            }
        }
        > .speaker-session--speaker-cards--card--content {
            padding-left: 15px;
            padding-top: 20px;
            &.speaker-session--speaker-cards--card--content__bottom-edge {
                @include thick-edge-bottom;
                border-left: unset;
            }
            box-sizing: border-box;
            @media screen and (max-width: 380px) {
                padding-left: 5px;
            }
            flex-basis: auto;
            flex-shrink: 1;
            flex-grow: 1;
            height: 200px;
            > .keynote-speaker-label {
                margin-top: 15px;
                color: $primary-open-sky-s3;
                font-family: 'Open Sans';
                font-size: 15.674px;
                font-style: normal;
                font-weight: 600;
                line-height: 25.078px;
                letter-spacing: 4.702px;
                text-transform: uppercase;
            }
            > h3 {
                > a {
                    @include header-level3($primary-open-sky-s2);
                }
                @media screen and (min-width: 1121px) {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                @media screen and (max-width: $reduce-vertical-padding-breakpoint) {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            > .speaker-session--speaker-cards--card--content--job-title {
                margin-top: 0;
                color: $secondary-sanfrancisco-fog-s5;
                @include body-small;
            }
        }
        > .speaker-session--speaker-cards--card--content__fullwidth {
            max-width: fit-content;
        }
        &:hover {
            h3 {
                a {
                    border-bottom: 2px solid $primary-pacific-blue;
                }
            }
        }
        h3 {
            a:focus{
                border-bottom: 2px solid $primary-pacific-blue;
            }
        }
        &:has(a:focus) {
            box-shadow: 0px 4px 12px 8px rgba(0, 99, 184, 0.50);
        }
        &:has(a:active) {
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25) inset;
        }
        &.speaker-session--speaker-cards--card__focused {
            box-shadow: 0px 4px 12px 8px rgba(0, 99, 184, 0.50);
        }
        &.speaker-session--speaker-cards--card__active {
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25) inset;
        }
    }
}
.conference-speaker-session-page {
    > h2 {
        color: $primary-open-sky-s3;
        @media screen and (min-width: $two-column-min-width) {
            margin-left: 80px;
        }
        @media screen and (max-width: $single-column-max-width) {
            margin-left: 20px;
        }
    }
    > .resource-grid {
        @media screen and (min-width: $two-column-min-width) {
            padding-left: 80px;
            padding-right: 40px;
        }
        @media screen and (max-width: $single-column-max-width) {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 40px;
        }
        .speaker-session--details-card.resource-grid--item {
            align-items: flex-start;
            max-width: unset;
            padding: 20px;
            @media screen and (min-width: 1350px) {
                height: 500px;
            }
            @media screen and (max-width: 1349px) and (min-width: 1221px) {
                height: 560px;
            }
            @media screen and (max-width: 1220px) and (min-width: 820px) {
                height: 360px;
            }
            @media screen and (max-width: 819px) and (min-width: 600px) {
                height: 380px;
            }
            @media screen and (max-width: 599px) and (min-width: 530px) {
                height: 420px;
            }
            @media screen and (max-width: 529px) and (min-width: 491px) {
                height: 450px;
            }
            @media screen and (max-width: 490px) and (min-width: 450px) {
                height: 490px;
            }
            @media screen and (max-width: 449px) {
                height: 510px;
            }
            @media screen and (min-width: 401px) and (max-width: 449px) {
                height: 560px;
            }
            @media screen and (max-width: 400px) and (min-width: 365px) {
                height: 570px;
            }
            @media screen and (max-width: 364px) {
                height: 600px;
            }
            .speaker-session--details-card--date {
                > h2 {
                    margin-top: 0;
                    margin-bottom: 0;
                    > a {
                        @include header-level2($primary-open-sky);
                    }
                }
            }
            > h3 {
                margin-bottom: 0;
                margin-top: 10px;
                > a {
                    @include header-level3($primary-open-sky-s2);
                }
            }
            > .speaker-session--details-card--related {
                > h4 {
                    margin: 0;
                }
                > h5 {
                    margin: 0;
                }
                .speaker-session--excerpt {
                    padding-top: 0;
                    padding-bottom: 0;
                    > p {
                        @include body-small;
                        @include trim-ellipsis-multiline(3);
                    }
                }
            }
        }
    }
}

h2.conference-speaker-session-page {
    color: $primary-open-sky-s3;
    @media screen and (min-width: $two-column-min-width) {
        margin-left: 80px;
    }
    @media screen and (max-width: $single-column-max-width) {
        margin-left: 20px;
    }
}
.conference-speaker-session-page {
    .conference-speakers--session-speakers {
        .speaker-session--speaker-cards {
            &.speaker-session--speaker-cards__filtered {
                &:not([data-filtercategory*="analytics-observability-security"]) {
                    .speaker-session--speaker-cards--card__categorized__analytics-observability-security {
                        display: none;
                    }
                }
                &:not([data-filtercategory*="search"]) {
                    .speaker-session--speaker-cards--card__categorized__search {
                        display: none;
                    }
                }
                &:not([data-filtercategory*="community"]) {
                    .speaker-session--speaker-cards--card__categorized__community {
                        display: none;
                    }
                }
                &:not([data-filtercategory*="operating-opensearch"]) {
                    .speaker-session--speaker-cards--card__categorized__operating-opensearch {
                        display: none;
                    }
                }
            }
        }
    }
}
.conference-speakers--session-speakers {
    > .conference-feature--categorization {
        @include page-element-left-right-padding;
        > .conference-feature--categorization--header-filters {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 20px;
            @media screen and (max-width: 834px) {
                flex-wrap: wrap;
            }
            > .conference-feature--categorization--header {
                flex-basis: 220px;
                flex-shrink: 0;
                flex-grow: 0;
                box-sizing: border-box;
                > h2 {
                    color: $primary-open-sky-s3;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            > .conference-feature--categorization--filters {
                box-sizing: border-box;
                flex-basis: calc(100% - 220px);
                flex-shrink: 0;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                column-gap: 20px;
                > .content-filter-dropdown-component {
                    width: 100%;
                    column-gap: 20px;
                    @media screen and (min-width: 835px) {
                        flex-wrap: nowrap;
                    }
                    @media screen and (max-width: 834px) {
                        flex-wrap: wrap;
                    }
                    > .content-filter-dropdown-component--dropdown {
                        flex-basis: 280px;
                        > .labeled-dropdown {
                            width: 280px;
                            > .labeled-dropdown--select {
                                width: 280px;
                            }
                        }
                    }
                    > .conference-speakers--session-speakers--filters--category-selector--filter-chips {
                        flex-basis: calc(100% - 260px);
                    }
                }
            }
        }
        > .conference-feature--categoriziation--list {
            @media screen and (max-width: $single-column-max-width) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 20px;
            }
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 20px;
            > div {
                flex-basis: content;
                display: inline-flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                white-space: nowrap;
                flex-wrap: nowrap;
                column-gap: 8px;
                > div:nth-of-type(1) {
                    flex-basis: 20px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 20px;
                    height: 20px;
                }
                > div:nth-of-type(2) {
                    @include header-level5($primary-open-sky-s3);
                }
            }
        }
    }
    .conference-speakers--session-speakers__categorized {
        display: grid;
        @media screen and (min-width: $two-column-min-width) {
            grid-template-columns: 1fr 1fr;
            padding: 40px 40px 0 80px;
            row-gap: 60px;
        }
        @media screen and (max-width: $single-column-max-width) {
            grid-template-columns: 1fr;
            padding: 0 20px 40px;
            row-gap: 20px;
        }
        @media screen and (max-width: $speaker-card-squish-for-height-breakpoint) {
            column-gap: 20px;
        }
        @media screen and (min-width: $wide-speaker-card-gap) {
            column-gap: 66px;
        }
        .conference-speakers--session-speakers__categorized--header {
            max-width: 600px;
            min-width: 400px;
            display: block;
            > .conference-speakers--session-speakers__categorized--header--text {
                box-sizing: border-box;
                width: 100%;
                > h2 {
                    color: $primary-open-sky-s3;
                    margin-top: 0;
                    @media screen and (max-width: $single-column-max-width) {
                        margin-bottom: 0;
                    }
                }
            }
            > .conference-speaker--session-speakers__categorized--header--filters {
                box-sizing: border-box;
                width: 100%;
            }
        }
        .conference-speakers--session-speakers__categorized--list {
            max-width: 600px;
            min-width: 400px;
            @media screen and (max-width: $single-column-max-width) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 20px;
            }
            @media screen and (min-width: $two-column-min-width) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                row-gap: 20px;
            }
            
            > div {
                display: inline-flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                white-space: nowrap;
                flex-wrap: nowrap;
                column-gap: 8px;
                > div:nth-of-type(1) {
                    flex-basis: 20px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 20px;
                    height: 20px;
                }
                > div:nth-of-type(2) {
                    @include header-level5($primary-open-sky-s3);
                }
            }
        }
    }
    .speaker-session--speaker-cards {
        &.speaker-session--speaker-cards__unfeatured__filtered {
            &:not([data-filtercategory*="analytics-observability-security"]) {
                .speaker-session--speaker-cards--card__categorized__analytics-observability-security {
                    display: none;
                }
            }
            &:not([data-filtercategory*="search"]) {
                .speaker-session--speaker-cards--card__categorized__search {
                    display: none;
                }
            }
            &:not([data-filtercategory*="community"]) {
                .speaker-session--speaker-cards--card__categorized__community {
                    display: none;
                }
            }
            &:not([data-filtercategory*="operating-opensearch"]) {
                .speaker-session--speaker-cards--card__categorized__operating-opensearch {
                    display: none;
                }
            }
        }
        > .speaker-session--speaker-cards--card.speaker-session--speaker-cards--card__categorized {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            > .speaker-session--speaker-cards--card--content {
                width: calc(100% - 20px - 70px);
                flex-basis: calc(100% - 20px - 70px);
                padding-left: 20px;
                flex-shrink: 0;
                flex-grow: 0;
                @media screen and (max-width: $single-column-max-width) {
                    > h3 {
                        line-height: 28px;
                    }
                }
            }
            > .speaker-session--speaker-cards--card__categorized--categories {
                width: 20px;
                padding-left: 40px;
                padding-right: 10px;
                flex-basis: 70px;
                flex-shrink: 0; 
                flex-grow: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: 100%;
                > div {
                    margin-top: 16px;
                }
            }
        }
    }
}

.hero-banner__fullwidth__responsive {
    @media screen and (min-width: 835px) {
        padding: 40px 40px 40px 80px;
        width: calc(100% - 120px);
    }
    @media screen and (max-width: 834px) {
        padding: 20px 20px 20px 20px;
        width: calc(100% - 40px);
    }
    &.conference-archive-landing {
        @media screen and (max-width: 834px) {
            padding: 0;
            width: 100%;
        }
    }
    > div {
        margin: 0;
        padding: 0;
        width: 100%;
        > img {
            object-fit: fill;
            width: 100%;
            height: auto;
        }
    }
}
.conference-exhibitors {
    // padding: 40px 80px;
    // @media screen and (max-width: 834px) {
    //     padding: 40px 20px;
    // }
    @include page-element-padding;
    .conference-exhibitors--grid.resource-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 40px;
        @media screen and (max-width: 1280px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
        }
        @media screen and (max-width: 639px) {
            grid-template-columns: 1fr;
            column-gap: 0;
        }
        > .resource-grid--item {
            width: 350px;
            height: auto;
            aspect-ratio: 1;
            cursor: pointer;
            @media screen and (min-width: 640px) and (max-width: 719px) {
                width: auto;
                max-width: calc(50% - 10px);
                min-width: 300px;
            }
            @media screen and (max-width: 639px) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
            .resource-grid--item--thumbnail {
                img {
                    width: 50%;
                    top: 45px;
                    left: 23%;
                }
            }
            .resource-grid--item--title-type {
                top: 10px;
                left: 20px;
                padding-left: 0;
            }
            .resource-grid--item--title > div {
                top: 10px;
                left: 20px;
            }
            @include thick-edge-bottom;
        }
    }
}

// The following are added for the schedule table

.speaker-session--speaker-cards-schedule {
    @extend .speaker-session--speaker-cards;
    @media screen and (max-width: $single-column-max-width) {
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    grid-template-columns: 1fr;
    
    .schedule-table {
        @media screen and (max-width: 834px) {
            display: none;
        }
        background: #FFF;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
        border: 12px solid white;
        border-collapse: separate;
        display: table;
        > thead {
            > tr {
                > th {
                    border-bottom: 12px solid white;
                    color: $primary-open-sky-s2;
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    padding: 12px 16px;
                    border-bottom: 12px solid white;
                }
                > td:nth-of-type(1) {
                    white-space: nowrap;
                }
                > td:nth-of-type(n+1) {
                    @include header-level5($secondary-sanfrancisco-fog-s5);
                }
            }
        }
    }
    .conference-schedule-mobile-table {
        @extend .basic-data-table;
        @media screen and (min-width: 835px) {
            display: none;
        }
        > thead {
            > tr {
                > th[colspan] {
                    font-family: 'Open Sans Condensed';
                    font-size: 21px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                    color: $primary-open-sky-s2;
                    padding: 10px 12px;
                    text-align: left;
                    border-bottom: 3px solid $primary-open-sky-s2;
                    border-left: none;
                }
            }
        }
        > tbody {
            > tr {
                > td.mobile-table-row--heading-cell {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 27px;
                    color: $primary-open-sky-s3;
                    padding: 10px 12px;
                    border-left: none;
                }
                > td.mobile-table-row--value-cell {
                    color: $secondary-sanfrancisco-fog-s5;
                    font-family: 'Open Sans';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    padding: 10px 12px;
                    border-left: none;
                    a {
                        overflow-wrap: anywhere;
                    }
                }
            }
            > tr.mobile-table-row__end-of-record {
                > td {
                    border-bottom: 3px solid $primary-open-sky-s2;
                }
            }
        }
    }
    .card {
        background-color: white;
        @media screen and (min-width: 835px) {
            padding:  30px 28px 54px 33px;
        }
        @media screen and (max-width: 834px) {
            padding: 0;
            > h3 {
                display: none;
            }
        }
        @include thick-edge-left;
        @include card-shadow;
    }

    a {
        text-decoration: none;
        color: $primary-open-sky-s2;
        &:visited {
            color: $secondary-sanfrancisco-fog-s4;
        }
        &:hover {
            text-decoration: underline;
        }
    }

    .operating {
        background: $secondary-golden-poppy-t3;
    }

    .search {
        background: $primary-deep-blue-sea-t3;
    }

    .community {
        background: $primary-open-sky-t3;
    }

    .analytics {
        background: $secondary-purple-sage-t3;
    }
}

.conference-archive-landing {
    &.landing-page-content__left-panel {
        > .landing-page-content__left-panel--content {
            > .landing-page-content__left-panel--content--body {
                > h2 {
                    @include header-level2($primary-open-sky-s3);
                }
                > .conference-archive--featured-grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    @media screen and (min-width: 1600px) {
                        gap: 80px;
                    }
                    @media screen and (min-width: 1440px) and (max-width: 1599px) {
                        gap: 40px;
                    }
                    @media screen and (min-width: 1300px) and (max-width: 1439px) {
                        gap: 20px;
                    }
                    @media screen and (max-width: 1299px) {
                        grid-template-columns: 1fr;
                        gap: 20px;
                    }
                    > .conference-archive--featured-grid--item {
                        @include thick-edge-bottom;
                        @include card-shadow;
                        @include clickable-card-interactive-states("conference-archive--featured-grid--item", $primary-open-sky);
                        background-color: #fff;
                        padding: 0;
                        @media screen and (min-width: 1300px) {
                            width: 350px;
                            aspect-ratio: 350 / 450;
                        }
                        @media screen and (max-width: 1299px) {
                            width: 100%;
                            aspect-ratio: 1;
                        }
                        
                        > .conference-archive--featured-grid--item--thumbnail {
                            width: 100%;
                            aspect-ratio: 350 / 200;
                            height: auto;
                            overflow-y: hidden;
                            img {
                                object-fit: cover;
                                width: 100%;
                                height: auto;
                            }
                        }
                        > .conference-archive--featured-grid--item--date {
                            width: 100%;
                            margin-top: 25px;
                            padding-left: 20px;
                            padding-right: 20px;
                            box-sizing: border-box;
                            > h6 {
                                @include header-level6($primary-open-sky-s3);
                                margin-bottom: 20px;
                            }
                        }
                        > .conference-archive--featured-grid--item--title {
                            height: 128px;
                            padding-left: 20px;
                            padding-right: 20px;
                            box-sizing: border-box;
                            > h2 {
                                @include header-level2($primary-open-sky);
                                margin-top: 0;
                                > a {
                                    color: $primary-open-sky;
                                    text-decoration: none;
                                }
                            }
                        }
                        > .conference-archive--featured-grid--item--category {
                            width: 100%;
                            box-sizing: border-box;
                            padding-left: 20px;
                            padding-right: 20px;
                            padding-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            column-gap: 10px;
                            > div {
                                box-sizing: border-box;
                                padding: 0;
                                flex-shrink: 0;
                                flex-grow: 0;
                            }
                            > div:nth-of-type(1) {
                                flex-basis: 20px;
                                height: 24px;
                            }
                            > div:nth-of-type(2) {
                                flex-basis: calc(100% - 20px);
                                @include header-level5($primary-open-sky-s3);
                            }
                        }
                    }
                }
            }
            > .landing-page-content__left-panel--content--side-panel {
                > .draft-3-landing-page--button-stack__with-label {
                    &:not(:first-of-type) {
                        margin-top: 20px;
                    }
                    > .draft-3-landing-page--button-stack__with-label--label {
                        > h2 {
                            @include header-level2(#000);
                            @media screen and (min-width: 835px) {
                                margin-bottom: 0;
                            }
                        }
                        > h3 {
                            @include header-level3(#000);
                            margin-top: 24px;
                            text-transform: capitalize;
                        }
                    }
                    > .draft-3-landing-page--button-stack {
                        @media screen and (min-width: 835px) {
                            &:nth-of-type(2) {
                                row-gap: 30px;
                            }
                        }
                        @media screen and (max-width: 428px) {
                            &:nth-of-type(2) {
                                > .draft-3-landing-page--button-stack--button {
                                    > .redesign-button--wrapper {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                > .draft-3-landing-page--button-stack {
                    margin-top: 70px;
                    margin-bottom: 40px;
                    flex-wrap: wrap;
                    row-gap: 30px;
                    > .redesign-button--wrapper {
                        @media screen and (max-width: 420px) {
                            width: calc(100vw - 70px);
                            > a.redesign-button--anchor {
                                width: calc(100vw - 70px);
                                height: 60px;
                                > .redesign-button--contents {
                                    justify-content: space-evenly;
                                    align-items: center;
                                    > .redesign-button--contents--icon-slot {
                                        padding: 18px 0 10px 0px;
                                    }
                                    > .redesign-button--contents--text-slot {
                                        font-size: 24px;
                                        padding-top: 0;
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: 410px) {
                            width: 100%;
                            > a.redesign-button--anchor {
                                width: 100%;
                            }
                        }
                    }
                }
                > .solutions-card-grid {
                    background: unset;
                    padding: 0;
                    > h2 {
                        @include header-level2(#000);
                    }
                    > .solutions-card-grid--card-wrapper {
                        @media screen and (min-width: 1401px) and (max-width: 1459px),
                        screen and (min-width: 1460px),
                        screen and (min-width: 835px) and (max-width: 1400px)
                        {
                            grid-template-columns: 1fr;
                            padding: 0;
                        }
                        @media screen and (min-width: 740px) and (max-width: 834px) {
                            grid-template-columns: 1fr 1fr;
                            column-gap: 20px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

.full-width-layout--content {
    &.conference-archive-landing__has-hero {
        border-top: none;
        > .full-width-layout--content--body.conference-archive-landing {
            &.conference-archive-landing__has-hero {
                > div {
                    padding-top: 0;
                }
            }
        }
    }
    > .full-width-layout--content--body.conference-archive-landing {
        &.conference-archive-landing__has-hero {
            > div {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
                width: 100%;
            }
        }
    }
}
