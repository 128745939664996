$primary-pacific-blue: #005EB8;
$primary-pacific-blue-t1: #137CC1;
$primary-pacific-blue-t2: #7FAADA;
$primary-pacific-blue-t3: #D9E4F3;
$primary-pacific-blue-s1: #00529E;
$primary-pacific-blue-s2: #004381;
$primary-pacific-blue-s3: #002950;

$primary-open-sky: #00A3E0;
$primary-open-sky-t1: #4FB3E3;
$primary-open-sky-t2: #9BCFED;
$primary-open-sky-t3: #E1F0F9;
$primary-open-sky-s1: #0085B8;
$primary-open-sky-s2: #006D97;
$primary-open-sky-s3: #003551;

$primary-deep-blue-sea: #003B5C;
$primary-deep-blue-sea-t1: #2C5E7A;
$primary-deep-blue-sea-t2: #7B96A9;
$primary-deep-blue-sea-t3: #E3E8EC;
$primary-deep-blue-sea-s1: #003553;
$primary-deep-blue-sea-s2: #002C45;
$primary-deep-blue-sea-s3: #001E30;

$primary-pacific-sky: #B9D9EB;
$primary-pacific-sky-t1: #C7DFEE;
$primary-pacific-sky-t2: #DCEBF4;
$primary-pacific-sky-t3: #F4F8FB;
$primary-pacific-sky-s1: #84A9CC;
$primary-pacific-sky-s2: #4C739B;
$primary-pacific-sky-s3: #163F66;

$secondary-purple-sage: #963CBD;
$secondary-purple-sage-t1: #AC68C5;
$secondary-purple-sage-t2: #CB9ED9;
$secondary-purple-sage-t3: #F4EAF6;
$secondary-purple-sage-s1: #823AA1;
$secondary-purple-sage-s2: #692F84;
$secondary-purple-sage-s3: #411D52;

$secondary-golden-poppy: #FFB81C;
$secondary-golden-poppy-t1: #FFC460;
$secondary-golden-poppy-t2: #FFD99B;
$secondary-golden-poppy-t3: #FFEFD6;
$secondary-golden-poppy-s1: #E6A530;
$secondary-golden-poppy-s2: #A77924;
$secondary-golden-poppy-s3: #6D4F17;

$secondary-midnight-sky: #002A3A;
$secondary-midnight-sky-t1: #F0F2F3;
$secondary-midnight-sky-t2: #BBC3C8;
$secondary-midnight-sky-t3: #7C8C96;
$secondary-midnight-sky-s1: #647782;
$sedondary-midnight-sky-s2: #4C636F;
$secondary-midnight-sky-s3: #1D3C4B;

$secondary-malibu-sunrise: #F65275;
$secondary-malibu-sunrise-t1: #FE7D8F;
$secondary-malibu-sunrise-t2: #FFAFB7;
$secondary-malibu-sunrise-t3: #FFEEEF;
$secondary-malibu-sunrise-s1: #D64A66;
$secondary-malibu-sunrise-s2: #AD3C53;
$secondary-malibu-sunrise-s3: #6A2534;

$secondary-seafoam-mint: #2CD5C4;
$secondary-seafoam-mint-t1: #6CDCD0;
$secondary-seafoam-mint-t2: #A9E9E1;
$secondary-seafoam-mint-t3: #DDF6F2;
$secondary-seafoam-mint-s1: #28BEB3;
$secondary-seafoam-mint-s2: #1D8B84;
$secondary-seafoam-mint-s3: #135B57;

$secondary-sanfrancisco-fog: #D9E1E2;
$secondary-sanfrancisco-fog-s1: #94A1A8;
$secondary-sanfrancisco-fog-s2: #CCD3D6;
$secondary-sanfrancisco-fog-t1: #F6F8F8;
$secondary-sanfrancisco-fog-s3: #697B84;
$secondary-sanfrancisco-fog-s4: #415662;
$secondary-sanfrancisco-fog-s5: #193442;

// gray
$white: #fff !default;
$gray-100: #f1f5f9 !default;
$gray-200: #e2e8f0 !default;
$gray-300: #cbd5e1 !default;
$gray-400: #94a3b8 !default;
$gray-500: #64748b !default;
$gray-600: #475569 !default;
$gray-700: #334155 !default;
$gray-800: #1e293b !default;
$gray-900: #0f172a !default;
$gray-950: #020617 !default;
$black: #020617 !default;




$light-theme-hero-control-active: $secondary-sanfrancisco-fog-s2;
$light-theme-hero-control-inactive: #fff;
$light-theme-hero-call-to-action-background: $secondary-malibu-sunrise;
$light-theme-hero-call-to-action-text: $secondary-malibu-sunrise-t3;
$light-theme-hero-call-to-action-hover: $secondary-malibu-sunrise-t2;

$light-theme-category-card-label: $primary-pacific-blue-s3;
$light-theme-category-card-content: $primary-open-sky-s2;
$light-theme-category-card-edge: $primary-open-sky-s2;
$light-theme-category-call-to-action-background: $primary-pacific-sky-t3;
$light-theme-category-call-to-action-text: $primary-deep-blue-sea;

$light-theme-navigation-background: #F1FBFF;

$event-category-community: $primary-open-sky;
$event-category-development: $secondary-malibu-sunrise;
$event-category-events: $secondary-seafoam-mint;

$community-member-author-persona: $primary-deep-blue-sea-t2;
$community-member-conference_speaker-persona: $primary-deep-blue-sea-t1;
$community-member-osci-persona: $primary-open-sky-s3;

$session-track-search-category: $primary-deep-blue-sea;
$session-track-analytics-observability-security: $secondary-purple-sage;
$session-track-operating-opensearch: $secondary-golden-poppy;

@mixin thick-edge-bottom($color: $primary-open-sky-s2) {
    border-bottom: $color 10px solid;
}
@mixin thick-edge-top {
    border-top: $primary-open-sky-s2 10px solid;
}
@mixin thick-edge-left {
    border-left: $primary-open-sky-s2 10px solid;
}
@mixin card-shadow {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}

@mixin gradient-deep-blue-sea {
    background: linear-gradient(180deg, #016BA7 0%, #011C2D 97.92%);
}

@mixin gradient-deep-blue-seafoam {
    background: linear-gradient(180deg, #2CD5C4 0%, #005EB8 100%);
}

@mixin gradient-golden-poppy {
    background: linear-gradient(180deg, #FFFAF2 0%, #FFB81C 52.84%, #E9A325 100%);
}

@mixin gradient-golden-poppy-dark {
    background: linear-gradient(180deg, #FFCF65 0%, #BF7509 100%);
}

@mixin gradient-golden-sunrise {
    background: linear-gradient(180deg, #F65275 0%, #FFB81C 100%);
}

@mixin gradient-malibu-sage {
    background: linear-gradient(180deg, #F65275 0%, #963CBD 100%);
}

@mixin gradient-malibu-sunrise {
    background: linear-gradient(180deg, #FFEEEF 0%, #FE7D8F 53.36%, #D64A66 100%);
}

@mixin gradient-malibu-sunrise-dark {
    background: linear-gradient(180deg, #F65275 0%, #8C3649 100%);
}

@mixin gradient-malibu-sunset {
    background: linear-gradient(180deg, #FFB81C 0%, #F65275 49.48%, #963CBD 100%);
}

@mixin gradient-malibu-sunset-vertical {
    background: linear-gradient(180deg, #F65275 0%, #FFB81C 100%);
}

@mixin gradient-malibu-sunset-dark {
    background: linear-gradient(180deg, #F65275 0%, #8C3649 100%);
}

@mixin gradient-open-sky {
    background: linear-gradient(180deg, #E1FAFF 0%, #98E3F4 48.15%, #00B7E0 100%);
}

@mixin gradient-open-sky-dark {
    background: linear-gradient(180deg, #68E3FF 0%, $primary-pacific-blue 100%);
}

@mixin gradient-purple-mint {
    background: linear-gradient(180deg, #963CBD 5.45%, #2CD5C4 100%);
}

@mixin gradient-purple-mint-vertical {
    background: linear-gradient(180deg, #963CBD 5.45%, #2CD5C4 100%);
}

@mixin gradient-purple-sage {
    background: linear-gradient(180deg, #FBF0FD 0%, #D4AAE1 29.09%, #963CBD 66.06%, #692F84 100%);
}

@mixin gradient-purple-sage-dark {
    // background: #963CBD;
    background: linear-gradient(180deg, #963CBD 0%, #692F84 50.52%, #411D52 100%);
}

@mixin gradient-seafoam-mint {
    background: linear-gradient(180deg, #DDF6F2 0%, #6CDCD0 47.11%, #11A297 100%);
}

@mixin gradient-seafoam-mint-dark {
    background: linear-gradient(180deg, #2CD5C4 0%, #135B57 100%);
}

@mixin gradient-seafoam-poppy {
    background: linear-gradient(180deg, #FFB81C 14.82%, #2CD5C4 100%);
}

@mixin gradient-vallarta-sunset {
    background: linear-gradient(180deg, #FFB81C 0%, #F65275 49.48%, #6FC5E9 100%);
}

@mixin clickable-card-interactive-states($card-class, $link-underline-color) {
    a {
        text-decoration: none;
    }
    &:has(a[href]) {
        cursor: pointer;
    }
    &:hover {
        a {
            border-bottom: 2px solid $link-underline-color;
            outline: none;
        }
    }
    &:has(a:focus) {
        box-shadow: 0px 4px 12px 8px rgba(0, 99, 184, 0.50);
        a {
            border-bottom: 2px solid $link-underline-color;
            outline: none;
        }
    }
    &:has(a:active) {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25) inset;
    }
    &.#{$card-class}__focused {
        box-shadow: 0px 4px 12px 8px rgba(0, 99, 184, 0.50);
        a {
            border-bottom: 2px solid $link-underline-color;
            outline: none;
        }
    }
    &.#{$card-class}__active {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25) inset;
    }
}
