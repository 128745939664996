@mixin center-flex($max-item-width: 100%) {
    display: flex;
    justify-content: center;
    > * {
        max-width: $max-item-width;
    }
}

@mixin page-element-padding() {
    @media screen and (min-width: 1200px) {
        padding: 40px 80px;
    }
    @media screen and (min-width:960px) and (max-width: 1199px) {
        padding: 30px 40px;
    }
    @media screen and (min-width: 739px) and (max-width: 959px) {
        padding: 30px 20px;
    }
    @media screen and (min-width: 431px) and (max-width: 739px) {
        padding: 30px;
    }
    @media screen and (max-width: 430px) {
        padding: 30px;
    }
    box-sizing: border-box;
    width: 100%;
}

@mixin page-element-left-right-padding() {
    @media screen and (min-width: 1200px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media screen and (min-width:960px) and (max-width: 1199px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media screen and (min-width: 739px) and (max-width: 959px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (min-width: 431px) and (max-width: 739px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media screen and (max-width: 430px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    box-sizing: border-box;
    width: 100%;
}
@mixin page-paragraph-padding() {
    @media screen and (min-width: 1200px) {
        padding: 1em 80px 0;
    }
    @media screen and (min-width:960px) and (max-width: 1199px) {
        padding: 1em 40px 0;
    }
    @media screen and (min-width: 739px) and (max-width: 959px) {
        padding: 1em 20px 0;
    }
    @media screen and (min-width: 431px) and (max-width: 739px) {
        padding: 1em 30px 0;
    }
    @media screen and (max-width: 430px) {
        padding: 1em 30px 0;
    }
    box-sizing: border-box;
    width: 100%;
}

@mixin inner-element-padding() {
    @media screen and (min-width: 1200px) {
        padding: 40px 40px;
    }
    @media screen and (min-width: 960px) and (max-width: 1199px) {
        padding: 30px 20px;
    }
    @media screen and (min-width: 739px) and (max-width: 959px) {
        padding: 30px 15px;
    }
    @media screen and (min-width: 431px) and (max-width: 739px) {
        padding: 30px;
    }
    @media screen and (max-width: 430px) {
        padding: 30px;
    }
    box-sizing: border-box;
    width: 100%;
}

@mixin page-element-margins() {
    @media screen and (min-width: 1200px) {
        margin: 40px 80px;
        width: calc(100% - 160px);
    }
    @media screen and (min-width:960px) and (max-width: 1199px) {
        margin: 30px 40px;
        width: calc(100% - 80px);
    }
    @media screen and (min-width: 739px) and (max-width: 959px) {
        margin: 30px 20px;
        width: calc(100% - 40px);
    }
    @media screen and (min-width: 431px) and (max-width: 739px) {
        margin: 30px;
        width: calc(100% - 60px);
    }
    @media screen and (max-width: 430px) {
        margin: 30px;
        width: calc(100% - 60px);
    }
}

@mixin page-element-left-right-margins() {
    @media screen and (min-width: 1200px) {
        margin-left: 80px;
        margin-right: 80px;
        width: calc(100% - 160px);
    }
    @media screen and (min-width:960px) and (max-width: 1199px) {
        margin-left: 40px;
        margin-right: 40px;
        width: calc(100% - 80px);
    }
    @media screen and (min-width: 739px) and (max-width: 959px) {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);
    }
    @media screen and (min-width: 431px) and (max-width: 739px) {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px);
    }
    @media screen and (max-width: 430px) {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px);
    }
}
