.resource-grid {
    @media screen and (max-width: 819px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 1.5em;
    }
    @media screen and (min-width: 820px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 20px;
        row-gap: 1em;
        flex-wrap: wrap;
    }
    @media screen and (min-width: 820px) and (max-width: 1220px) {
        row-gap: 2em;
    }

    .resource-grid--item {
        background-color: #f5f7f7;
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;

        .resource-grid--item--thumbnail {
            position: relative;
            padding-top: 66.66%;
            width: 100%;
            overflow: hidden;
        }
        @media screen and (min-width: 1270px) {
            .resource-grid--item--thumbnail {
                height: auto;
            }
        }

        .resource-grid--item--thumbnail img {
            width: 100%;
            height: auto;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .resource-grid--item--title {
            flex-grow: 1;
            text-align: left;
            width: 100%;
            display: flex;
            align-items: flex-end;
        }

        .resource-grid--item--title > div {
            display: -webkit-box;
            overflow: hidden;
            max-width: 100%;
            font-size: 18px;
            line-height: 1.2;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2.4em;
        }

        .resource-grid--item--title-type {
            text-transform: capitalize;
            width: 100%;
            text-align: left;
            padding: 0;
            color: #002a3a;
            font-weight: bold;
        }

        .resource-grid--item--title-text {
            vertical-align: text-top;
        }

        .resource-grid--item--link {
            text-decoration: none;
            line-height: 1;
        }
        .resource-grid-item--byline {
            @include header-level5;
            width: 100%;
            padding-left: 2em;
            padding-bottom: 0.5em;
        }

        @media screen and (max-width: 819px) {
            min-height: auto;
            max-height: unset;
            min-width: auto;
            max-width: unset;
            height: auto;
            justify-content: flex-start;
            .resource-grid--item--thumbnail {
                height: auto;
            }
        }

        @media screen and (max-width: 861px) {
            height: auto;
            width: 100%;
        }
        @media screen and (min-width: 820px) and (max-width: 919px) {
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            flex-grow: 1;
            flex-shrink: 1;
        }
        @media screen and (min-width: 920px) and (max-width: 1220px) {
            width: calc(50% - 2em);
            max-width: calc(50% - 2em);
            flex-grow: 1;
            flex-shrink: 1;
            box-sizing: border-box;
        }

        @media screen and (min-width: 1221px) {
            flex-grow: 1;
            flex-shrink: 1;
            width: calc(33% - 2em);
            max-width: calc(33% - 2em);
            box-sizing: border-box;
        }
    }
}
