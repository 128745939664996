
.blog-nav {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .next, .prev {
        display: block;
        flex: 1;
    }
    .next {
        padding-right: 0.5em;
    }
    .prev {
        padding-left: 0.5em;
        text-align: right;
    }
}

.whats-new {
    .whats-new-post {
        h4 {
            margin-top: 20px;
            margin-bottom: 0;
        }
        p {
            margin-top: 0.5em;
        }
    }
    .whats-new-post + .whats-new-post h4{
        margin-top: 30px;
    }
}

.sidebar {

    .feature-box {
        margin-top: 2em; 
        .feature-content {
            padding-right: 70px;
        }
        img {
            height: 55px;
            width: 55px;
            float: right;
        }
        margin-bottom: 2em;
    }
}

.source-page {
    dt {
        a {
            text-decoration: none;
            display: inline-block;
            color: $core;
            background-color: $background-darker;
            padding: 0.25em 1em 0 1em;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
    }
    dd {
        padding: 0.5em 1em;
        
        background-color: $background-darker;
        margin-bottom: 1em;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    dd p {
        margin-top: 0;
        margin-bottom: 0.5em;
    }
    .link-readmore {
        display: inline-block;
        margin-right: 1.5em;
    }
}

.author-photo {
    width: 180px;
    height: auto;
}


#faq {
    li{
        list-style-type: none;
    }
    li h3 a i {
        float: right;
        display: block;
    }
    li h3 span {
        opacity: 0.9;
    }
    ol li ul li {
        list-style-type: disc;
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
.img-centered {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.downloads-page {
    .layout-2col {
        .col {
            width: 48%;
            margin: 0 4% 0 0 ;
        }
        .col + .col {
            margin: 0;
        }
    }
}

.banner-alert, .version-alert {
    @include sans-serif;
    @include warning-stripes;
}

.version-alert {
    padding: 0.5em;
    margin-top: 1em;
    position:-webkit-sticky; 
    position:sticky; 
    top:0;
    p {
        margin: 0;
    }
}

.imported-post {
    margin-left: -1em;
    margin-right: -1em;
    background-color: #ffe761;
    padding: 1em;
    @include sans-serif;
    @include warning-stripes;
}

.share-container {
    @include sans-serif;
    color: $text-light;

    padding-bottom: 2em;    
}

#share-buttons {
    display: inline-block; 
    vertical-align: middle;
    :after {
        content: ""; 
        display: block; 
        clear: both;
    }
    > div {
        position: relative;
        text-align: left; 
        height: 36px; 
        width: 32px; 
        float: left; 
        text-align: center;
        > svg {
            height: 16px; 
            fill: $background-darkest; 
            margin-top: 10px;
        }
    }
    > div:hover {
        cursor: pointer;
    }
    > div.facebook:hover > svg {fill: #3B5998;}
    > div.twitter:hover > svg {fill: #55ACEE;}
    > div.linkedin:hover > svg {fill: #0077b5;}
    > div.mail:hover > svg {fill: #7D7D7D;}
    > div.facebook > svg {
        height: 18px; 
        margin-top: 9px;
    }
    > div.twitter > svg {
        height: 20px; 
        margin-top: 8px;
    }
    > div.linkedin > svg {
        height: 19px; 
        margin-top: 7px;
    }
    > div.mail > svg {
        height: 14px; 
        margin-top: 11px;
    }
}

.author-social-media {
    a {
        text-decoration: none;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
    }
}

.additional-author-info {
    border-top: 1px solid $secondary-sanfrancisco-fog-s2;
    margin-top: 2rem;
    font-style: italic;
}

.tree, .tree ul {
    margin:0;
    padding:0;
    list-style:none
  }
  .tree ul {
    margin-left:1em;
    position:relative
  }
  .tree ul ul {
    margin-left:.5em
  }
  
  .tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid
  }
  .tree li {
    margin:0;
    line-height:2em;
    position:relative
  }
  .tree li li {
  padding:0 1em;
  }
  .tree ul li:before {
    content:"";
    display:block;
    width:10px;
    height:0;
    border-top:1px solid;
    margin-top:-1px;
    position:absolute;
    top:1em;
    left:0
  }
  .tree ul li:last-child:before {
    background:#fff;
    height:auto;
    top:1em;
    bottom:0
  }

.artifact-download-tree {
    margin: 1em;
    padding: 1em;
    background-color: #fff;
    @include sans-serif;
}
.artifact-name {
    margin-bottom:0;
}
.artifact-and-heading {
    margin-left: 1em;
    padding-bottom: 1em;
   
}
.artifact-and-heading + .artifact-and-heading {
    border-top: 1px solid $line;
}

.artifact-group {
    h2 {
        padding-top: 0.75em;
        border-top: 1px solid $line;
    }
}

#content-main .artifact-group:first-child h2 {
    border-top: none;
}

#content-main {
    img {
        max-width: 100%;
    }
}

.artifact-container {
    display: flex;
    flex-flow: row wrap;
    gap: 1em;

    .artifact-explain { 
        width : 66%;
        flex-grow: 1;

        >p:last-child {
            margin-bottom: 0;
        }
    }
    .download-panel {
        flex-grow: 1;
        @include sans-serif;

        select {
            width: 100%;
        }
        label, 
        .extra_links a {
            font-size: 12px;
            display: block;
        }
        .extra_links .cta {
            font-size: 15px;
            max-width: none;
        }
    }
    .extra-instructions-group {
        width: 100%;
        flex-shrink: 0;

        .highlight {
            margin: 0 0 15px;
        }
    }

    .instruction-label {
        margin: 0 0 2px;

        a {
            @include sans-serif;
            font-weight: 400;
            font-size: 12px;
            float: right;
            margin-top: 6px;
            line-height: 1;

            &:visited {
                color: inherit;
            }
        }
    }
}

.artifact-standalone {
    @include sans-serif;
    .cta {
        display: inline-block;
    }
}

.tag-pick {
    padding: 0.25em;
    cursor: pointer;
    display: inline-block;
    border: 1px solid $line;
    margin-bottom: 1em;
}
.tag-pick.tag-off {
    background-color: $highlight;
    color: $white;
}


.testimonial {
    &.square {
        .attribution {
            img {
                height: 8em;
            }
        }
    }
    p {
        margin: 0;
        font-style: italic;
        padding-bottom: 0.25em;
    }

    .attribution {
        color: $text-light;
        text-align: right;
        @include sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.75em;
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        .readable {
            margin-right: 1em;
        }
        img {
            padding-left: 1em;
            padding-right: 1em;
            border-left: 1px solid $line;
            height: 4em;
            width: auto;
        }
    }
    border-top: 1px solid $line;
    padding: 0.5em;
    border-left: 1px solid $line;
    margin-bottom: 3em;
    padding-left: 1em;
    margin-left: 4em;
    margin-right: 4em;
}

.tutorial-layout {
    img.img-fluid {
        padding: 1em;
        border: 1px solid $text;
    }
    background-color: $white;
    padding: 1em 2em;
    body {
        background-color: $white;
    }
    .simple-banner {
        top: 1em;
        bottom: 1em;
        border-bottom: 1px solid $line;
        img {
            max-width: 200px;
        }
    }
    .highlight pre {
        border: none;
    }
    pre code {
        padding: 0;
    }
    code {
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 4px;
    }
    code, .highlight {
        background-color: $text-light-darkest;
        color: $highlight-lighter;
        &::selection { 
            background-color: $warning; /* WebKit/Blink Browsers */
            color: $text-light-darkest;
        }
    }
    h1,h2,h3,h4,h5,h6 {
        color: $text;
    }
    .simple-footer {
        color: $text-light;
        padding-top: 4em;
        border-top: 1px solid $line;
        font-size: 0.5em;
        a { color: $text-light; }
    }

    .gitpod-visible, 
    body:not(.gitpod-dashboards-ready) .gitpod-dashboards-ready-visible {
        display: none;
    }



    .gitpod {
        
        &.gitpod-dashboards-ready {
            .gitpod-dashboards-ready-visible {
                display : inherit;
            }
            .gitpod-dashboards-ready-hidden {
                display: none;
            } 
        }
        .gitpod-visible {
            display: inherit;
        }
        .gitpod-hidden {
            display: none;
        }
    }
}

.img__outlined {
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
}

.img__no-outline {
    border-radius: 0;
    box-shadow: none;
}

.page--blog-post {
    #content-main {
        img {
            padding: 10px;
            border: 1px solid $line-lighter;
            border-radius: 4px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
        }
    }

    .highlight pre, .highlight code {
        border: none;
    }
    pre code {
        padding: 0;
    }
    code {
        font-size: 0.9rem;
        padding: 2px 4px;
        border-radius: 4px;
        border: 1px solid #EAEAEA;
    }
    code, .highlight {
        background-color: $background-darker;
    }
}


.embed-video {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    aspect-ratio: 16 / 9;

    @extend %rounded;


    &.twitch {
      aspect-ratio: 310 / 189;
    }
  
    &.file {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      margin-bottom: 0;
    }

  }


// NEW 

// HERO
.homepage-hero-banner-background-wrapper {
    position: relative;
    height: 500px;
    overflow: hidden;
    background-image: url(/assets/img/homepage-assets/Banner_HomePage_Hero_Desktop.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.homepage-hero-banner-background-mobile-wrapper {
    position: relative;
    height: 500px;
    overflow: hidden;
    background-image: url(/assets/img/homepage-assets/Banner_HomePage_Hero_Mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
}
// Textoverlay for the hero
.homepage-hero-heading-background-container {
    position: fluid;
}


.homepage-hero-heading {
    color: $primary-open-sky-s2;
    // font-size: 80px;
    font-family: "Open Sans";
    font-weight: 1000;
    word-wrap: break-word;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.homepage-hero-subtext {
    color: $primary-open-sky-s3;
    font-size: 21px;
    font-family: "Open Sans";
    font-weight: 400;
    line-height: 36px;
    word-wrap: break-word;
}

// SHARED
.homepage-section-heading {
    color: $primary-open-sky-s3;
    font-size: 30px;
    font-family: "Open Sans condensed";
    font-weight: 700;
    margin-bottom: 30px;
}
.homepage-section-heading-link {
    color: $primary-open-sky-s3;
    font-size: 30px;
    font-family: "Open Sans condensed";
    font-weight: 700;
    margin-bottom: 30px;
    text-decoration: underline;
    &:visited {
        color: inherit;
    }
    &:hover {
        color: $primary-open-sky;
    }

}
.homepage-section-subheading {
    color: $primary-open-sky-s2;
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 30px;
    line-height: 36px;
}
.homepage-section-subheading-link {
    color: $primary-open-sky-s2;
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 30px;
    line-height: 36px;

    &:hover {
        text-decoration: underline;
    }
}
.homepage-section-subtext {
    color: $primary-open-sky-s3;
    font-size: 21px;
    font-family: "Open Sans";
    font-weight: 400;
    line-height: 32px;
    word-wrap: break-word; 
}




// CAPABILITIES
.community_icon_left_card_layout {
    max-width: 800px;
    position: relative;
    // border-top-left-radius: 25px;

    &::after {
        content: "";
        position: absolute;
        height: 6px;
        width: 100%;
        background-color: $primary-open-sky-s2;
        bottom: 0px;
        left: 0;
    }

    .capability_card_title {
        color: $primary-open-sky-s1;
        font-size: 24px;
        font-family: "Open Sans";
        font-weight: 700;
        line-height: 36px;
        justify-content: flex-start;
        word-wrap: normal;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .capability_card_text {
        color: $secondary-sanfrancisco-fog-s4;
        font-size: 18px;
        font-family: "Open Sans";
        font-weight: 400;
        word-wrap: break-word;

        text-align: start;
        line-height: 27px;
    }
    .capability_card_link {
        color: $secondary-sanfrancisco-fog-s5;
        font-size: 15px;
        font-family: "Open Sans";
        font-weight: 400;
        word-wrap: normal;
        text-align: start;
        text-decoration: none;

        z-index: 2;
        position: relative;
        &:hover {

            color: $primary-open-sky;
            text-decoration: underline;

        }
    }
}


// PLATFORM
.homepage-banner {
    max-width: 100%;
    height: auto;
}



// COMMUNITY 
.homepage-community-section-wrapper {
    background-color: $primary-pacific-sky-t3;

    .homepage-section-subtext {
        line-height: 44px;
    }

    .homepage-community-icon-card {

        background-color: $primary-pacific-sky-t3;
        text-decoration-color: $primary-open-sky-s2 !important; 


        .card-icon {
            justify-content: center;
            margin: 30px;
            width: 100px;
            height: 100px;

            .icon-homepage-community-withSurroundingCircle {
                width: 115px;
                height: 115px;
            }
        }
        .card-title {
            color: $primary-open-sky-s2;
            text-decoration-color: $primary-open-sky-s2; 
            font-size: 21px;
            font-family: 'Open Sans';
            font-weight: 700;
            line-height: 36px;
            word-wrap: break-word;
        }
    }
}


// OPEN SEARCH CON
.homepage-opensearchcon-gallary-card {
    object-fit: cover;
}

// FOUNDATION
.homepage-foundation-section-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-image: url(/assets/img/homepage-assets/Banner_Foundation_Background.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.homepage-foundation-image {
    width: 100%;
}
// Textoverlay for the hero
.homepage-foundation-content-container {
    position: fluid;
}
.button-primary {
    background-color: $primary-open-sky;
    border-radius: 2px;
    border-color: $secondary-sanfrancisco-fog-t1;
    color: $secondary-sanfrancisco-fog-t1;
    --bs-btn-padding-y: 15px;
    --bs-btn-padding-x: 25px;

    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    &:hover {
        border-color: $primary-open-sky;
        color: $primary-open-sky; 
        background-color: $secondary-sanfrancisco-fog-t1;
    }  
}


// COMPONENTS
.card-verticallystacked-icon {
    background-color: inherit;
    border: none; 
    align-items: center;
 
    &:hover,
    &:active,
    &:focus {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
        text-decoration: underline;
        text-decoration-color: inherit; 
    } 

    .card-icon {
        justify-content: center;
    }     

}


.card-horizontallystacked-icon {
    background-color: none;
    border: none;  
    box-shadow: 4px 4px 12px 1px rgba(0, 0, 0, 0.25);
}

.card-fullimageoverlay {
    width: 100%;

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    } 
}
