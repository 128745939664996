$community-voices-testimonial-overrides-citation-height: 790px;
.landing-page-solutions-community-voices--wrapper {
    > .landing-page-solutions-community-voices {
        .landing-page-community-voices--arrow-margin-wrapper {
            &.coralogix {
                .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 25%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 120px;
                            > img {
                                width: 70%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 75px;
                            > img {
                                width: 90%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 75%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 120px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 75px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
            &.dowjones {
                .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 30%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 120px;
                            > img {
                                width: 70%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 75%;
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 75px;
                            > img {
                                width: 90%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 70%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 120px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 75px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
            &.goldman {
                .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 20%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 265px;
                            > img {
                                width: 70%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 80%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 265px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
            &.openinfra {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 30%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 110px;
                            > img {
                                width: 60%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 100px;
                            > img {
                                width: 85%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 70%;
                            // > blockquote {
                            //     > p {
                            //         @include body-small;
                            //     }
                            // }
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 110px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 100px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
            &.pinterest {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 20%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 195px;
                            > img {
                                width: 50%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 175px;
                            > img {
                                width: 50%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 80%;
                            // > blockquote {
                            //     > p {
                            //         @include body-small;
                            //     }
                            // }
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 195px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 175px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
            &.quantiphi {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 20%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 125px;
                            > img {
                                width: 50%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // Note regarding the negative margin and image position:
                            // It is a kludge to reduce the amount of visible empty space
                            // that is created by the image itself within the flex item
                            // at this breakpoint. FYI.
                            flex-basis: 90px;
                            margin-bottom: -30px;
                            > img {
                                position: relative;
                                top: -30px;
                                width: 100%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 80%;
                            // > blockquote {
                            //     > p {
                            //         @include body-small;
                            //     }
                            // }
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 125px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        // @media screen and (max-width: $community-voices-smallest-max-size) {
                        //     flex-basis: calc($community-voices-testimonial-overrides-citation-height - 90px - 30px);
                        // }
                    }
                }
            }
            &.rackspace {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 20%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 110px;
                            > img {
                                width: 50%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 100px;
                            > img {
                                width: 90%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 80%;
                            // > blockquote {
                            //     > p {
                            //         @include body-small;
                            //     }
                            // }
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 110px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        // @media screen and (max-width: $community-voices-smallest-max-size) {
                        //     flex-basis: calc($community-voices-testimonial-overrides-citation-height - 100px);
                        // }
                    }
                }
            }
            &.sap {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 25%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 195px;
                            > img {
                                width: 50%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 160px;
                            > img {
                                width: 90%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 75%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 195px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 160px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
            &.wipro {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 20%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 195px;
                            > img {
                                width: 50%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 190px;
                            > img {
                                width: 65%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 80%;
                            // > blockquote {
                            //     > p {
                            //         @include body-small;
                            //     }
                            // }
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 195px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        // @media screen and (max-width: $community-voices-smallest-max-size) {
                        //     flex-basis: calc($community-voices-testimonial-overrides-citation-height - 190px);
                        // }
                    }
                }
            }
            &.zoom {
                > .landing-page-community-voices--testimonial {
                    > .landing-page-community-voices--testimonial--logo {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 25%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            padding: 0;
                            flex-basis: 100px;
                            > img {
                                width: 50%;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: 85px;
                            > img {
                                width: 85%;
                            }
                        }
                    }
                    > .landing-page-community-voices--testimonial--citation {
                        @media screen and (min-width: $community-voices-horz-view-min) {
                            flex-basis: 75%;
                        }
                        @media screen and (max-width: $community-voices-vert-view-max) {
                            // flex-basis: calc($community-voices-testimonial-overrides-citation-height - 100px);
                            // > blockquote {
                            //     > p {
                            //         @include body-text;
                            //     }
                            // }
                            > cite {
                                margin-top: 20px;
                            }
                        }
                        @media screen and (max-width: $community-voices-smallest-max-size) {
                            flex-basis: calc($community-voices-testimonial-overrides-citation-height - 85px);
                            // > blockquote {
                            //     > p {
                            //         @include body-medium;
                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }
}
