// Navbar

.mobile-nav-topLevel-wrapper {
    overflow-x: hidden;

    .mobile-nav-topLevel-accordion {

        --bs-accordion-btn-bg: #F4F8FB;                   // Background color of the top accordion header
        --bs-accordion-active-bg: #F4F8FB;                // (When active) Background color of the top accordion header
        --bs-accordion-bg: #003551;                       // Background color of the active accordion body
        
        --bs-accordion-btn-focus-box-shadow: none;

        .mobile-nav-topLevel-accordion-toggler {

            &.accordion-button {
                  
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-list' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5'/%3E%3C/svg%3E");
                    --bs-accordion-btn-icon-width: 30px;
                    transition: all 0.5s;
                }
                &:not(.collapsed)::after {
    
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
                    --bs-accordion-btn-icon-width: 30px;
                  }
            }  
        }



    } 
}
.mobile-nav-contentSection-accordion {
    overflow-x: hidden;
    
    --bs-accordion-btn-bg: #003551;                   // (BUTTON) Background color of the top accordion header
    --bs-accordion-active-bg: #003551;                // (BUTTON) (When active) Background color of the top accordion header
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-bg: #003551;                       // (BODY) Background color of the active accordion body
    
    --bs-accordion-active-color: #9BCFED;               // (BUTTON) (When active) Text color of the top accordion header
    --bs-accordion-btn-color: #E1F0F9;

    --bs-accordion-border-color: $primary-open-sky-s2;

    .mobile-nav-contentSection-accordion-toggler {

        &.accordion-button {
            --bs-accordion-btn-icon-width: 40px;
            --bs-accordion-btn-icon-transform: rotate(-180deg);
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-plus' fill='%230085B8' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4'/%3E%3C/svg%3E");
                transition: all 0.5s;
            }  
            &:not(.collapsed)::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-dash' fill='%239BCFED' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8'/%3E%3C/svg%3E");
                
            }
            &:hover,
            &:active,
            &:focus {
                box-shadow: none;
            }
        }
    }
    .mobile-content-header {
        font-size: 21px;
        font-weight: 700;
        line-height: 36px;
        text-decoration: none;
    }
    .mobile-content-text-link {
        color: $primary-open-sky-t3;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-decoration: none;
    }

} 





.btn-primary-dark {

    --bs-btn-padding-y: 15px;
    --bs-btn-padding-x: 20px;
    background-color: $primary-open-sky-s1;
    border-radius: 0px;
    color: $secondary-sanfrancisco-fog-t1;
    
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 30px;
    text-wrap: nowrap;

    // --bs-btn-active-color: $secondary-golden-poppy;
    // --bs-btn-active-bg:$primary-open-sky-s3;
    > .button-content-icon-svg {
        fill: $secondary-sanfrancisco-fog-t1;
        margin-bottom: 2px;
    }
    &:active {
        background-color: $primary-open-sky-s3 !important;
    }
    &:hover {
        background-color: $primary-open-sky-s3;
        color: $secondary-sanfrancisco-fog-t1;
    }
    &:active > .button-content {

        > .button-content-icon {
            > .button-content-icon-svg{
                fill: $secondary-golden-poppy;
            }
        }
        > .button-content-text {
            color: $secondary-golden-poppy;
        }

    }
    &:visited {
        color: $secondary-sanfrancisco-fog-t1;
    }
}



.button-content-icon-svg {
    fill: $secondary-sanfrancisco-fog-t1;
    margin-bottom: 2px;

    // &:has( + input:active) {
    //     fill: $secondary-golden-poppy;
    // }

    // &:active {
    //     fill: $secondary-golden-poppy;
    // }
    // &:hover {
    //     fill: $secondary-sanfrancisco-fog-t1;
    // }
    &:visited {
        fill: $secondary-sanfrancisco-fog-t1;
    }
}


// .navbar-downloads-button {
//     &:hover {
//         border-color: #006D97;
//         color: #003551; 
//     }   
// }

.button-dark {
    // --bs-btn-color: $secondary-sanfrancisco-fog-t1;
    --bs-btn-padding-y: 15px;
    --bs-btn-padding-x: 15px;
    background-color: $primary-open-sky-s2;
    border-radius: 0px;
    border-color: $secondary-sanfrancisco-fog-t1;
    color: $secondary-sanfrancisco-fog-t1 !important;
    
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    &:hover {
        border-color: #006D97;
        color: #003551; 
        // background-color: #C7DFEE;
    }  
}

.button-light {
    width: 100%;
    height: 100%;
    padding-left: 50px; 
    padding-right: 50px; 
    padding-top: 10px; 
    padding-bottom: 10px; 
    background-color: #F4F8FB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    // border-color: #B9D9EB;

    // FONT
    color: #003B5C;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    &:hover {
        background-color: #F4F8FB;
        color: $primary-open-sky; 
    }  
}

.top-nav-desktop {
    background-color: #F4F8FB !important;
}

.nav-link {
    color: $primary-open-sky-s3;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    background-color: #F4F8FB !important;
    padding: 0;

    &.accordion-button {
        --bs-accordion-btn-icon-width: 12px;
        --bs-accordion-transition: 0.5s;
        
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
            transition: all 0.5s;
            margin-left:8px;
        }
    }

    &:active,
    &:focus,
    &:hover {
        color: $primary-open-sky-s2;
        text-decoration: underline;
        box-shadow: none;
        outline: none;
    }
}

.nav-drawer-triangle {
    z-index: 0;
}

.nav-search-icon {
    background-color: $primary-open-sky-s1;
    padding-top: 100px;

    &:active,
    &:focus,
    &:hover {
        background-color: $primary-open-sky-s3;
        box-shadow: none;
        outline: none;  
    }

    &.accordion-button {
        &::after {
            background-image: initial;
            width: 0;
        }

        &:not(.collapsed)::after {
            background-image: initial;
            width: 0;
        }
    }
}

.top-nav-button {
    background-color: #F4F8FB;
    border-radius: 6px;
    border-color: #B9D9EB;
    color: #006D97;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;

    &:hover {
        border-color: #006D97;
        color: $primary-open-sky-s2; 
        background-color: #C7DFEE;
    }  
}

.icon-div-box {
    width: 50px;
    height: 50px;
    padding: 0;
    margin-left: 10px;
}
.icon-div-box-mobile {
    width: 35px;
    height: 35px;
    padding-top: 10px;
    margin-left: 5px;
}


.nav-drawer {

    .nav-drawer-body-short {
        min-height: 300px;
    }

    .nav-drawer-body-med {
        min-height: 500px;
    }

    .nav-drawer-body-tall {
        min-height: 650px;
    }

    .drawer-content-header {
        color: $primary-open-sky-s3;
        font-family: 'Open Sans Condensed';
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 700;
        text-decoration: none;
        z-index: 1;
    }
    .drawer-content-header-link {
        color: $primary-open-sky-s3;
        font-family: 'Open Sans Condensed';
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 700;
        text-decoration: underline;
        z-index: 1;

        &:hover {
            color: $primary-open-sky;
            text-decoration: underline;
        }
    }
    .drawer-content-subheader {
        color: $primary-open-sky-s2;
        font-size: 21px;
        font-weight: 700;
        line-height: 36px;
        text-decoration: none;
        z-index: 1;  
    }
    .drawer-content-subheader-link {
        color: $primary-open-sky-s2;
        font-size: 21px;
        font-weight: 700;
        line-height: 36px;
        text-decoration: underline;
        z-index: 1;
        &:hover {
            color: $primary-open-sky;
            text-decoration: underline;
        }
    }    
    .drawer-content-text {
        color: $primary-open-sky-s3;
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        word-wrap: break-word;
        text-decoration: none;
        z-index: 1;
    }
    .drawer-content-text-link {
        color: $primary-open-sky-s3;
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        word-wrap: break-word;
        text-decoration: none;
        z-index: 1;

        &:hover {
            color: $primary-open-sky;
            text-decoration: underline;
        }
    }
    .hero-card {
        box-shadow: none;
    }
    .navbar-featured-post-image {
        max-height: 200px;
    }

    .navbar-featured-post-title {
        color: $primary-open-sky-s3;
        font-family: 'Open Sans Condensed';
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        word-wrap: break-word;
        text-decoration: none;
    }

    .blog-table {
        .megamenu-table-heading {
            color: $primary-open-sky-s2;
            font-size: 21px;
            font-weight: 700;
            line-height: 24px;
            text-decoration: none;
            height: 50px;
        }

        .megamenu-blog-title {
            color: $primary-open-sky-s3;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-decoration: none;
            word-wrap: break-word;
            height: 100px;
        }
        .megamenu-blog-date {
            width: 80px;
            color: #193442;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-decoration: none;
            word-wrap: break-word;
        }
    }

}





.top-banner-search--field-with-results--field--wrapper--search-component--search-input {
    outline: 0 !important;
    border-color: $primary-open-sky-s2;
    box-shadow: none !important;

    &:focus,
    &:active {
        border-color: $primary-open-sky-s2;
    }
}

.search-form {

    box-shadow: none !important;


}
.search-form-icon {
    &:has( + input:focus) {
        border-color: $primary-open-sky-s2;
    }
}
.search-form-input {

    &:focus,
    &:active {
        border-color: $primary-open-sky-s2;
    }


}



.top-banner-search--field-with-results--field--wrapper--search-component--search-results  {

    max-height: 480px;
    overflow-y: scroll; 

    background-color: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    > .top-banner-search--field-with-results--field--wrapper--search-component--search-results-wrapper {
        @include clearfix;
        display: block;
        width: 100%;
        height: auto;
        > .top-banner-search--field-with-results--field--wrapper--search-component--search-results--result {
            padding: 0 16px;
            box-sizing: border-box;
            margin-top: 0;
            @media screen and (min-width: 1019px) {
                padding: 16px;
            }
            > a {
                font-family: "Open Sans Condensed", sans-serif;
                line-height: 1.6;
                font-weight: bold;
                background: linear-gradient(rgb(238, 235, 238) 0%, rgb(238, 235, 238) 100%) repeat-x 0 100%/1px 1px;
                color: #0055a6;
                font-size: 20px;
                text-decoration: #0055a6;
                white-space: unset;
                display: inline;
                &:hover {
                    background-image: linear-gradient(rgba(0, 85, 166, 0.45) 0%, rgba(0, 85, 166, 0.45) 100%);
                }
                > cite {
                    font-size: 12px;
                    font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
                    color: #002A3A;
                    text-decoration: none;
                    font-style: normal;
                    display: block;
                    line-height: 1;
                    font-weight: normal;
                }
            }
            > span {
                font-size: 14px;
                color: #1B4859;
                line-height: 1.4;
                display: block;
                overflow-wrap: break-word;
                font-weight: normal;
                &:only-child {
                    text-align: center;
                    padding: 16px;
                }
            }
        }
    }
}




// PREVIOUS NAVBAR STYLING
$header-banner-min-width: 1019px;
$header-banner-mobile-breakpoint: 1018px;
$header-logo-max-left: 80px;
$header-navigation-max-right: 40px;
$max-container: 1440px;

#top {
    @include clearfix;
    background: $light-theme-navigation-background;
    overflow: hidden;
    margin: 0;
    padding: 10px 0 6px;
    position: relative;
    z-index: 0;
    box-sizing: content-box;
    & * {
        box-sizing: content-box;
    }
    @media screen and (min-width: $header-banner-min-width) {
        z-index: 9;
        overflow: visible;
    }
   
    > .navigation-container {
        max-width: 1440px;
        position: relative;
        box-sizing: content-box;
        @media screen and (min-width: 740px) and (max-width: 1045px) {
            padding: 0 20px;
        }
        @media screen and (max-width: $header-banner-mobile-breakpoint) {
            padding: 0;
        }
        @media screen and (min-width: $header-banner-min-width) and (max-width: 1099px) {
            margin: 0 auto;
            padding: 0 20px;
        }
        @media screen and (min-width: 1100px) {
            margin: 0 auto;
            padding: 0 4.1666666667%;
        }
        > .navigation-container--logo {
            color: #fff;
            display: block;
            float: left;
            font-size: 35px;
            font-weight: 700;
            height: 43px;
            margin: 10px;
            overflow: hidden;
            text-decoration: none;
            text-indent: 100%;
            position: relative;
            // NOTE: The min() function name is deliberatedly misspelled with an upercase M to workaround an outdated SCSS compiler
            // dependency used by the Just the Docs theme 0.3.3.
            left: Min(80px, calc(100% - $header-banner-min-width));
            box-sizing: content-box;
            @media screen and (max-width: 1339px) {
                left: 0;
            }
            @media screen and (min-width: $header-banner-min-width) {
                margin-left: 0;
            }
            svg {
                position: absolute;
                top: 1px;
                left: 1px
            }
        }
        .menu-button {
            font-size: 35px;
            color: $primary-deep-blue-sea-t1;
            cursor: pointer;
            display: block;
            float: right;
            height: 45px;
            line-height: 48px;
            margin: 4px 10px;
            text-align: center;
            text-decoration: none;
            width: 45px;
            > i {
                position: relative;
                top: -1px;
                &:nth-of-type(1) {
                    display: inline-block;
                }
                &:nth-of-type(2) {
                    display: none;
                }
            }
            @media screen and (min-width: $header-banner-min-width) {
                display: none;
            }
            span {
                @include visuallyhidden;
            }
            &.active {
                > i {
                    &:nth-of-type(1) {
                        display: none;
                    }
                    &:nth-of-type(2) {
                        display: inline-block;
                    }
                }
            }
        }
        > .navigation-container--nested-nav-wrapper {
            margin-top: 17px;
            margin-right: 40px;
            width: 100%;
            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                background: $primary-open-sky-s3;
                height: 0;
                margin-top: 0;
                overflow: hidden;
                transition: all 0.3s ease-out;
            }
            @media screen and (min-width: $header-banner-min-width) {
                float: right;
                height: auto;
                max-height: none;
                transition: none;
                width: auto;
                overflow: visible;
            }
            @media screen and (max-width: 1339px) {
                margin-right: 0;
            }
            &.active {
                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                    height: calc(100vh - 65px);
                    overflow-y: auto;
                    > .navigation-container--nested-nav-wrapper--nested-nav {
                        @include clearfix;
                        height: auto;
                        clear: both;
                    }
                }
            }
            > .navigation-container--nested-nav-wrapper--nested-nav {
                padding: 0;
                margin: 0;
                list-style-type: none;
                > li {
                    color: $primary-open-sky-s3;
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: left;
                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                        padding-bottom: 20px;
                        padding-top: 15px;
                        border-bottom-color: $primary-open-sky-s2;
                        border-bottom-style: solid;
                        border-bottom-width: 2px;
                        margin: 0;
                    }
                    @media screen and (min-width: $header-banner-min-width) {
                        position: relative;
                        float: left;
                        margin-top: 0;
                        margin-left: 0;
                        margin-bottom: 0;
                        margin-right: 5px;
                        &:hover {
                            > ul {
                                display: block;
                                visibility: visible;
                                opacity: 1;
                                top: calc(100% + 7px);
                                transition: opacity 0.25s ease-out, top 0.25s ease-out, visibility 0s;
                            }
                        }
                    }
                    @media screen and (min-width: 1040px) and (max-width: 1102px) {
                        margin-right: 0.9vw;
                    }
                    @media screen and (min-width: 1103px) and (max-width: 1149px) {
                        margin-right: 1vw;
                    }
                    @media screen and (min-width: 1150px) and (max-width: 1369px) {
                        margin-right: 15px;
                    }
                    @media screen and (min-width: 1370px) {
                        margin-right: 30px;
                    }
                    > .nested-nav--top-menu-item-wrapper {
                        display: block;
                        padding: 0;
                        margin: 0;
                        border: none;
                        width: 100%;
                        height: auto;
                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            padding: 0;
                            margin: 0;
                            border: none;
                            width: 100%;
                        }
                        &.nested-nav-top-menu-item--wrapper__has_children {
                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                position: relative;
                                z-index: 10;
                            }
                        }
                        > .nested-nav--top-menu-item-wrapper--link {
                            display: block;
                            width: 100%;
                            margin: 0;
                            height: auto;
                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                flex-basis: calc(100% - 30px - 20px);
                                height: 30px;
                                flex-grow: 1;
                                flex-shrink: 1;
                                box-sizing: content-box;
                            }
                            > a {
                                &.in-category,
                                &:hover {
                                    color: $primary-open-sky-s2;
                                    text-decoration: underline;
                                    text-decoration-color: $primary-open-sky-s2;
                                    text-decoration-thickness: 2px;
                                    text-underline-offset: 6px;
                                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                        color: $primary-open-sky-t2;
                                        text-decoration-color: $primary-open-sky-t2;
                                    }
                                }
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    @include header-level3($primary-open-sky-t3);
                                    padding-left: 30px;
                                    text-decoration: none;
                                    &:active {
                                        color: $primary-open-sky;
                                        text-decoration-color: $primary-open-sky;
                                    }
                                }
                                @media screen and (min-width: $header-banner-min-width) {
                                    display: inline;
                                    @include header-level6($primary-open-sky-s3);
                                    text-decoration: none;
                                }
                            }
                        }
                        > .nested-nav--top-menu-item-wrapper--toggle {
                            @media screen and (min-width: $header-banner-min-width) {
                                display: none;
                            }
                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                flex-basis: 30px;
                                width: 30px;
                                height: 30px;
                                padding: 0 30px 0 0;
                                flex-shrink: 0;
                                flex-grow: 0;
                                box-sizing: content-box;
                            }
                            > .opensearch-toggle-button--wrapper {
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    display: inline-block;
                                    padding: 0;
                                    margin: 0;
                                    height: 30px;
                                    width: 30px;
                                }
                                > .opensearch-toggle-button-link {
                                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                        display: none;
                                        padding: 0;
                                        margin: 0;
                                        height: 30px;
                                        width: 30px;
                                    }
                                    &.opensearch-toggle-button-link__visible {
                                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                        &.nested-nav--top-menu-item--wrapper__without-children {
                            > .nested-nav--top-menu-item-wrapper--link {
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    flex-basis: 100%;
                                    width: 100%;
                                    flex-grow: 0;
                                    flex-shrink: 0;
                                }
                            }
                            > .nested-nav--top-menu-item-wrapper--toggle {
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    flex-basis: 0;
                                    width: 0;
                                    display: none;
                                }
                            }
                        }
                    }
                    > ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        min-width: 100%;
                        @media screen and (min-width: $header-banner-min-width) {
                            background: #fff;
                            border-radius: 2px;
                            box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
                            box-sizing: border-box;
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            min-width: 100%;
                            transition: opacity .25s ease-out, top 0s .5s, visibility 0s .5s;
                            visibility: hidden;
                        }
                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                            height: 0;
                            opacity: 0;
                            transition: all .25s ease-out;
                            z-index: 1;
                            position: relative;
                            &[expanded] {
                                height: var(--expanded-height);
                                opacity: 1;
                            }
                            &.nested-nav--menu__mobile-hidden-collapsed {
                                display: none;
                            }
                        }
                        > li {
                            color: $primary-open-sky-s3;
                            display: block;
                            font-size: 15px;
                            font-weight: 700;
                            line-height: 16px;
                            margin: 0;
                            > a {
                                text-decoration: none;
                                @media screen and (min-width: $header-banner-min-width) {
                                    @include header-level6($primary-open-sky-s3);
                                    width: max-content;
                                    display: block;
                                    white-space: nowrap;
                                    padding: 18px 0 0 0;
                                    margin-left: 18px;
                                    margin-right: 18px;
                                    &:hover,
                                    &.in-category {
                                        color: $primary-open-sky-s2;
                                        text-decoration: underline;
                                        text-decoration-color: $primary-open-sky-s2;
                                        text-decoration-thickness: 2px;
                                        text-underline-offset: 6px;
                                    }
                                }
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    @include body-text;
                                    color: $primary-open-sky-t3;
                                    padding-left: 30px;
                                    &.in-category {
                                        color: #006D97;
                                        text-decoration: underline;
                                        text-decoration-color: #006D97;
                                        text-decoration-thickness: 2px;
                                        text-underline-offset: 6px;
                                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                            color: $primary-open-sky-t2;
                                            text-decoration-color: $primary-open-sky-t2;
                                        }
                                    }
                                    &:active {
                                        color: $primary-open-sky;
                                        text-decoration-color: $primary-open-sky;
                                    }
                                }
                            }
                            &.nested-nav--top-menu-item__without-grandchildren ~ .nested-nav--top-menu-item__has-grandchildren {
                                @media screen and (min-width: $header-banner-min-width) {
                                    margin-bottom: 15px;
                                    & + .nested-nav--top-menu-item__has-grandchildren {
                                        margin-top: -15px;
                                        margin-bottom: 3px;
                                    }
                                }
                            }
                            &.nested-nav--top-menu-item__has-grandchildren + .nested-nav--top-menu-item__has-grandchildren {
                                &:last-of-type {
                                    padding-bottom: 15px;
                                }
                            }
                            &:last-of-type {
                                > a {
                                    padding-bottom: 18px;
                                }
                            }
                            > .nested-nav--top-menu-item-wrapper__has-grandchildren--wrapper {
                                display: block;
                                padding: 0;
                                margin: 0;
                                border: none;
                                width: 100%;
                                height: auto;
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    padding: 0;
                                    margin: 0;
                                    border: none;
                                    width: 100%;
                                }
                                &.nested-nav-top-menu-item--wrapper__has_children__has-grandchildren {
                                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                        position: relative;
                                        z-index: 10;
                                    }
                                }
                                > .nested-nav--top-menu-item-wrapper__has-grandchildren--wrapper--link {
                                    display: block;
                                    width: 100%;
                                    margin: 0;
                                    height: auto;
                                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                        flex-basis: calc(100% - 30px - 20px);
                                        height: 30px;
                                        flex-grow: 1;
                                        flex-shrink: 1;
                                        box-sizing: content-box;
                                    }
                                    > a {
                                        &.in-category,
                                        &:hover {
                                            @media screen and (min-width: $header-banner-min-width) {
                                                color: $primary-open-sky-s2;
                                                text-decoration-color: $primary-open-sky-s2;
                                            }
                                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                                color: $primary-open-sky-t2;
                                                text-decoration-color: $primary-open-sky-t2;
                                            }
                                            text-decoration: underline;
                                            text-decoration-thickness: 2px;
                                            text-underline-offset: 6px;
                                        }
                                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                            @include header-level3($primary-open-sky-t3);
                                            padding-left: 30px;
                                            text-decoration: none;
                                        }
                                        @media screen and (min-width: $header-banner-min-width) {
                                            display: block;
                                            width: max-content;
                                            @include header-level6($primary-open-sky-s3);
                                            text-decoration: none;
                                            padding: 18px 0 0 0;
                                            margin-left: 18px;
                                            margin-right: 18px;
                                        }
                                    }
                                }
                                > .nested-nav--top-menu-item-wrapper--toggle {
                                    @media screen and (min-width: $header-banner-min-width) {
                                        display: none;
                                    }
                                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                        flex-basis: 30px;
                                        width: 30px;
                                        height: 30px;
                                        padding: 0 30px 0 0;
                                        flex-shrink: 0;
                                        flex-grow: 0;
                                        box-sizing: content-box;
                                    }
                                    > .opensearch-toggle-button--wrapper {
                                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                            display: inline-block;
                                            padding: 0;
                                            margin: 0;
                                            height: 30px;
                                            width: 30px;
                                        }
                                        > .opensearch-toggle-button-link {
                                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                                display: none;
                                                padding: 0;
                                                margin: 0;
                                                height: 30px;
                                                width: 30px;
                                            }
                                            &.opensearch-toggle-button-link__visible {
                                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:not(:first-child) {
                                > ul {
                                    left: 0;
                                }
                            }
                            @media screen and (min-width: $header-banner-min-width) {
                                &:hover {
                                    > ul {
                                        display: block;
                                        visibility: visible;
                                        opacity: 1;
                                        top: calc(100% + .5em);
                                        transition: opacity .25s ease-out, top .25s ease-out, visibility 0s;
                                    }
                                }
                            }
                            > ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                min-width: 100%;
                                @media screen and (min-width: $header-banner-min-width) {
                                    background: #fff;
                                    border-radius: 2px;
                                    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
                                    box-sizing: border-box;
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                    min-width: 100%;
                                    transition: opacity .25s ease-out, top 0s .5s, visibility 0s .5s;
                                    visibility: hidden;
                                }
                                @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                    height: 0;
                                    opacity: 0;
                                    transition: all .25s ease-out;
                                    z-index: 1;
                                    position: relative;
                                    &[expanded] {
                                        height: var(--expanded-height);
                                        opacity: 1;
                                    }
                                    &.nested-nav--menu__mobile-hidden-collapsed {
                                        display: none;
                                    }
                                }
                                > li {
                                    color: $primary-open-sky-s3;
                                    display: block;
                                    font-size: 15px;
                                    font-weight: 700;
                                    line-height: 16px;
                                    margin: 0;
                                    > a {
                                        text-decoration: none;
                                        @media screen and (min-width: $header-banner-min-width) {
                                            @include header-level6($primary-open-sky-s3);
                                            width: max-content;
                                            display: block;
                                            white-space: nowrap;
                                            padding: 18px 0 0 0;
                                            margin-left: 18px;
                                            margin-right: 18px;
                                            &:hover,
                                            &.in-category {
                                                color: $primary-open-sky-s2;
                                                text-decoration: underline;
                                                text-decoration-color: $primary-open-sky-s2;
                                                text-decoration-thickness: 2px;
                                                text-underline-offset: 6px;
                                            }
                                        }
                                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                            @include body-text;
                                            color: $primary-open-sky-t3;
                                            padding-left: 30px;
                                            &.in-category {
                                                color: $primary-open-sky-t2;
                                                text-decoration: underline;
                                                text-decoration-color: $primary-open-sky-t2;
                                                text-decoration-thickness: 2px;
                                                text-underline-offset: 6px;
                                            }
                                            &:active {
                                                color: $primary-open-sky;
                                                text-decoration-color: $primary-open-sky;
                                            }
                                        }
                                    }
                                }
                            }
                            &.nested-nav--top-menu-item__has-grandchildren {
                                position: relative;
                                > ul {
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;
                                    min-width: 100%;
                                    @media screen and (min-width: $header-banner-min-width) {
                                        background: #fff;
                                        border-radius: 2px;
                                        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
                                        box-sizing: border-box;
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 100%;
                                        opacity: 0;
                                        transition: opacity .25s ease-out, top 0s .5s, visibility 0s .5s;
                                        visibility: hidden;
                                        > li {
                                            &:last-of-type {
                                                > a {
                                                    padding-bottom: 18px;
                                                }
                                            }
                                        }
                                    }
                                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                        height: 0;
                                        opacity: 0;
                                        transition: all .25s ease-out;
                                        z-index: 1;
                                        position: relative;
                                        &[expanded] {
                                            height: var(--expanded-height);
                                            opacity: 1;
                                        }
                                        &.nested-nav--menu__mobile-hidden-collapsed {
                                            display: none;
                                        }
                                    }
                                }
                                @media screen and (min-width: $header-banner-min-width) {
                                    &:hover {
                                        > ul {
                                            display: block;
                                            visibility: visible;
                                            opacity: 1;
                                            transition: opacity .25s ease-out, top .25s ease-out, visibility 0s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:not(:first-child) {
                        > ul {
                            left: 0;
                        }
                    }
                    @media screen and (min-width: $header-banner-min-width) {
                        &:hover {
                            > ul {
                                display: block;
                                visibility: visible;
                                opacity: 1;
                                top: calc(100% + .5em);
                                transition: opacity .25s ease-out, top .25s ease-out, visibility 0s;
                            }
                        }
                    }
                }
                > li.top-banner-search {
                    display: inline-flex;
                    align-items: flex-start;
                    @media screen and (max-width: $header-banner-mobile-breakpoint) {
                        display: flex;
                        padding: 15px 30px;
                        height: 60px;
                        box-sizing: content-box;
                    }
                    > .top-banner-search--field-with-results {
                        display: inline-flex;
                        align-items: flex-start;
                        min-width: 200px;
                        justify-content: stretch;
                        @media screen and (min-width: $header-banner-min-width) {
                            position: relative;
                            top: -4px;
                        }
                        @media screen and (max-width: $header-banner-mobile-breakpoint) {
                            display: flex;
                            width: 100%;
                            position: relative;
                            z-index: 11;
                        }
                        > .top-banner-search--field-with-results--field {
                            flex-basis: 100%;
                            flex-grow: 1;
                            flex-shrink: 1;
                            > .top-banner-search--field-with-results--field--wrapper {
                                position: relative;
                                > .top-banner-search--field-with-results--field--wrapper--search-component {
                                    position: relative;
                                    z-index: 2;
                                    > .top-banner-search--field-with-results--field--wrapper--search-component--input-wrap {
                                        position: relative;
                                        z-index: 1;
                                        transition: height linear 200ms;
                                        @media screen and (min-width: 462.5px) {
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            box-shadow: none;
                                            transition: width ease 400ms;
                                        }
                                        >.top-banner-search--field-with-results--field--wrapper--search-component--search-input {
                                            padding-left: 9.6px;
                                            padding-right: 6px;
                                            transition: padding-right linear 200ms;
                                            border-radius: 6px;
                                            border-color: $primary-pacific-sky-t2;
                                            border-width: 0;
                                            background-color: $primary-pacific-sky-t2;
                                            @include header-level6($primary-open-sky-s2);
                                            box-sizing: border-box;
                                            position: absolute;
                                            width: 100%;
                                            &::placeholder {
                                                @include header-level6($primary-open-sky-s2);
                                            }
                                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                                height: 60px;
                                                padding-top: 0;
                                                padding-bottom: 0;
                                                font-size: 18px;
                                            }
                                            @media screen and (min-width: $header-banner-min-width) {
                                                height: 45px;
                                                position: relative;
                                                top: -6px;
                                                border: none;
                                            }
                                            &:focus {
                                                outline: 0;
                                            }
                                            &:focus + .top-banner-search--field-with-results--field--wrapper--search-component--search-label .top-banner-search--field-with-results--field--wrapper--search-component--search-icon {
                                                color: #0055A6;
                                            }
                                        }
                                        > .top-banner-search--field-with-results--field--wrapper--search-component--search-spinner {
                                            display: none; 
                                            font-weight: 700; 
                                            outline: 0; 
                                            user-select: none; 
                                            position: absolute; 
                                            padding-right: 9.6px;
                                            height: 100%;
                                            right: 0;
                                            left: auto;

                                            &.top-banner-search--field-with-results--field--wrapper--search-component--search-spinner__spinning {
                                                display: flex;
                                                & ~ .top-banner-search--field-with-results--field--wrapper--search-component--search-label {
                                                    display: none;
                                                }
                                            }
                                            > i {
                                                border-color: rgba(77, 131, 153, 0.2);
                                                position: relative;
                                                animation: spin 0.6s infinite linear;
                                                border-width: 3px;
                                                border-style: solid;
                                                border-radius: 100%;
                                                display: inline-block;
                                                width: 18px;
                                                height: 18px;
                                                vertical-align: middle;
                                                align-self: center;
                                                &:before {
                                                    content: "";
                                                    border: 3px solid rgba(77, 131, 153, 0);
                                                    border-top-color: rgba(77, 131, 153, 0.8);
                                                    border-radius: 100%;
                                                    display: block;
                                                    left: -3px;
                                                    position: absolute;
                                                    top: -3px;
                                                    height: 100%;
                                                    width: 100%;
                                                    box-sizing: content-box;
                                                }
                                            }
                                        }
                                        > .top-banner-search--field-with-results--field--wrapper--search-component--search-label {
                                            position: absolute;
                                            display: flex;
                                            height: 100%;
                                            right: 4px;
                                            top: 17px;
                                            padding-left: 0;
                                            padding-right: 0;
                                            transition: right linear 200ms;
                                            @media screen and (min-width: $header-banner-min-width) {
                                                padding-left: 32px;
                                                transition: padding-left linear 200ms;
                                            }
                                            @media screen and (max-width: $header-banner-mobile-breakpoint) {
                                                width: 25px;
                                                height: 26px;
                                                top: 17px;
                                            }
                                            > .top-banner-search--field-with-results--field--wrapper--search-component--search-icon {
                                                width: 19.2px;
                                                height: 19.2px;
                                                align-self: center;
                                                color: #4D8399;
                                            }
                                        }
                                    }
                                    > #search-results.top-banner-search--field-with-results--field--wrapper--search-component--search-results {
                                        position: absolute;
                                        left: 0;
                                        display: none;
                                        width: 100%;
                                        height: 50px;
                                        max-height: 500px;
                                        overflow-y: auto;
                                        background-color: #fff;
                                        border-bottom-right-radius: 4px;
                                        border-bottom-left-radius: 4px;
                                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}




