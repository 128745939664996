$calendar-week-max-height: 185px;
$calendar-day-max-width: 180px;
$calendar-month-default-week-count: 5;

.events--calendar--details--card {
    box-sizing: border-box;
    width: 350px;
    height: 350px;
    padding: 45px 30px 10px 30px;
    @include thick-edge-bottom;
    @include card-shadow;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    &.events--calendar--details--card__events-page {
        justify-content: start;
    }
    &:hover {
        svg {
            > rect {
                fill: $secondary-sanfrancisco-fog-s1;
            }
        }
    }
    &:has(a:active),
    &.events--calendar--details--card__visited,
    &.events--calendar--details--card__active {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25) inset;
        svg {
            > rect {
                fill: $secondary-sanfrancisco-fog-s4;
            }
        }
    }
    > h4.events--calendar--body--week--day--event--details--category {
        margin-top: 0;
        margin-bottom: 10px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }
    > h3.events--calendar--body--week--day--event--details--name {
        color: $primary-open-sky-s2;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        margin-top: 0;
        margin-bottom: 0;
        > a {
            color: $primary-open-sky-s2;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;
            &:hover,
            &:active {
                color: $primary-open-sky;
                text-decoration: underline;
            }
        }
    }
    > h6.events--calendar--body--week--day--event--details--date {
        margin-top: 10px;
        margin-bottom: 0;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }
    // NOTE: The selectors for the headers with the id's defined
    //       is used as it has been observed that event authors are
    //       free to use whatever HTML or Kramdown in the content, and
    //       Jekyll will provide whatever is there lexicographicaly first
    //       as an excerpt which can break the design. Jekyll creates id 
    //       attributes for headers using a formatted version of the text of
    //       the header. The card styles do not use id attributes. So, if there
    //       is an id attribute then it is coming from the Jekyll excerpt.
    //       Normalizing the presentation of the excerpts helps to keep the
    //       design consistent.
    > p,
    > h6[id],
    > h5[id],
    > h4[id],
    > h3[id],
    > h2[id],
    > h1[id],
    .events--calendar--body--week--day--event--details--description {
        @include trim-ellipsis-multiline;
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 10px 0;
        & * {
            font-weight: normal;
            font-style: normal;
        }
    }
    > p.events--calendar--details--card--hosts {
        @include body-small;
    }
    > h5.events--calendar--body--week--day--event--details--host {
        margin-top: 0;
        margin-bottom: 0;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        > a {
            color: $primary-pacific-blue;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            &:hover {
                color: $primary-open-sky;
                text-decoration: none;
            }
            &:active {
                color: $primary-pacific-blue-s2;
                text-decoration: none;
            }
        }
    }
}

.full-width-layout--content 
> .full-width-layout--content--body 
> div.events--calendar--wrapper {
    @include page-element-left-right-margins;
    padding: 0;
    p.events--calendar--no-events-message {
        @include body-text;
        text-align: left;
        white-space: nowrap;
        overflow-x: visible;
        &.events--calendar--no-events-message__centered {
            text-align: center;
        }
    }
    > .events--calendar--filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 20px;
        row-gap: 30px;
        margin: 30px 0;
        @media screen and (min-width: 835px) {
            align-items: center;
        }
        @media screen  and (max-width: 834px) {
            align-items: flex-start;
        }
        > .events--calendar--filters--view-mode-toggle {
            flex-basis: 70px;
            flex-shrink: 0;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            flex-wrap: nowrap;
            @media screen and (max-width: 834px) {
                display: none;
            }
            > .events--calendar--filters--view-mode-toggle--item {
                flex-basis: 35px;
                flex-shrink: 0;
                flex-grow: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                box-sizing: border-box;
                border: 1px solid $primary-open-sky-s2;
                background: #fff;
                &.events--calendar--filters--view-mode-toggle--item__selected {
                    background: $primary-open-sky-s2;
                    > a {
                        > svg {
                            > path {
                                fill: #fff;
                            }
                        }
                    }
                }
                &:nth-of-type(1) {
                    border-radius: 6px 0px 0px 6px;
                }
                &:nth-of-type(2) {
                    border-radius: 0px 6px 6px 0px;
                }
                > a {
                    font-size: 0;
                    > svg {
                        width: 19px;
                        height: 20px;
                        > path {
                            fill: $primary-open-sky-s2;
                        }
                    }
                }
            }
        }
        > .events--calendar--filters--category-selector {
            flex-basis: calc(100% - 100px - 250px);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            @media screen and (min-width: 835px) {
                flex-wrap: nowrap;
            }
            @media screen and (max-width: 834px) {
                flex-wrap: wrap;
            }
            > .events--calendar--filters--category-selector--dropdown {
                flex-basis: 200px;
                flex-shrink: 0;
                flex-grow: 0;
                width: 200px;
            }
            > .events--calendar--filters--category-selector--filter-chips {
                flex-basis: 250px;
                flex-shrink: 0;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                > div {
                    flex-basis: content;
                    flex-grow: 1;
                    flex-shrink: 1;
                }
                > p.events--calendar--no-events-message {
                    line-height: 0;
                }
            }
        }
        > .events--calendar--filters--in-person-toggle {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            column-gap: 10px;
            &.events--calendar--filters--in-person-toggle__disabled {
                > div:nth-of-type(1) {
                    cursor: not-allowed;
                    > svg {
                        > rect {
                            fill: $secondary-sanfrancisco-fog-s1;
                        }
                        > circle {
                            fill: #fff;
                        }
                    }
                }
                > div:nth-of-type(2) {
                    > label {
                        color: $secondary-sanfrancisco-fog-s1;
                    }
                }
            }
            &.events--calendar--filters--in-person-toggle__toggled-off {
                > div:nth-of-type(1) {
                    transform: rotateZ(180deg);
                    position: relative;
                    top: 2px;
                    > svg {
                        > rect {
                            fill: #fff;
                        }
                        > circle {
                            fill: $primary-open-sky-s2;
                        }
                    }
                }
            }
            > div:nth-of-type(1) {
                cursor: pointer;
                flex-basis: 40px;
                height: 20px;
                flex-shrink: 0;
                flex-grow: 0;
                box-sizing: border-box;
                > svg {
                    > rect {
                        fill: $primary-open-sky-s2;
                    }
                    > circle {
                        fill: #fff;
                    }
                }
            }
            > div:nth-of-type(2) {
                flex-basis: content;
                flex-grow: 0;
                flex-shrink: 0;
                > label {
                    white-space: nowrap;
                    @include header-level5(#000);
                }
            }
        }
    }
    > .events--calendar__list-view__online-only {
        .events--calendar__list-view--event-card__online {
            .tall-card__no-image--grid--card {
                &:not(.events--calendar__list-view--event-card__online) {
                    display: none;
                }
            }
        }
    }
    > .events--calendar__list-view__filtered {
        &:not([data-filtercategory*="community"]) {
            .events--calendar__list-view--event-card__community {
                display: none;
            }
        }
        &:not([data-filtercategory*="development"]) {
            .events--calendar__list-view--event-card__development {
                display: none;
            }
        }
        &:not([data-filtercategory*="events"]) {
            .events--calendar__list-view--event-card__events {
                display: none
            }
        }
    }
    > .events--calendar {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
        background: #FFF;
        box-sizing: border-box;
        padding: 0;
        margin-bottom: 80px;
        &.events--calendar__online-only {
            .events--calendar--body--week--day--event {
                &:not(.events--calendar--body--week--day--event__online) {
                    display: none;
                }
            }
        }
        &.events--calendar__filtered {
            &:not([data-filtercategory*="community"]) {
                .events--calendar--body--week--day--event__community {
                    display: none;
                }
            }
            &:not([data-filtercategory*="development"]) {
                .events--calendar--body--week--day--event__development {
                    display: none;
                }
            }
            &:not([data-filtercategory*="events"]) {
                .events--calendar--body--week--day--event__events {
                    display: none;
                }
            }
        }
        > .events--calendar--head {
            width: 100%;
            height: 64px;
            margin-top: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            > .events--calendar--head--month-year {
                flex-basis: 50%;
                flex-shrink: 1;
                flex-grow: 0;
                text-align: left;
                @include header-level2($primary-open-sky-s2);
            }
            > .events--calendar--head--prev-next {
                flex-basis: 50%;
                flex-shrink: 1;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                > .events--calendar--head--prev-next--prev {
                    margin-right: 20px;
                    > a {
                        @include header-level6($primary-open-sky-s2);
                        text-decoration: none;
                    }
                    > span {
                        @include header-level6(#333);
                    }
                }
                > .events--calendar--head--prev-next--next {
                    > a {
                        @include header-level6($primary-open-sky-s2);
                        text-decoration: none;
                    }
                    > span {
                        @include header-level6(#333);
                    }
                }
            }
        }
        > .events--calendar--body {
            > .events--calendar--body--weekday-names {
                border-top: 1px solid rgba(218, 220, 224, 0.60);
                border-bottom: 1px solid rgba(218, 220, 224, 0.60);
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                > .events--calendar--body--weekday-names--name {
                    flex-basis: calc(100% / 7);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    @include header-level4(#333);
                }
            }
            > .events--calendar--body--weeks {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                height: auto;
                max-height: calc($calendar-month-default-week-count * $calendar-week-max-height);
                &.events--calendar--body-weeks__4 {
                    max-height: calc($calendar-week-max-height * 4);
                }
                &.events--calendar--body--weeks__5 {
                    max-height: calc($calendar-week-max-height * 5);
                }
                &.events--calendar--body--weeks__6 {
                    max-height: calc($calendar-week-max-height * 6);
                }
                > .events--calendar--body--week {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    &:not(:first-of-type) {
                        > .events--calendar--body--week--day {
                            &:not(:last-of-type) {
                                border-top: 1px solid rgba(218, 220, 224, 0.60);
                                border-right: 1px solid rgba(218, 220, 224, 0.60);
                            }
                            &:last-of-type {
                                border-top: 1px solid rgba(218, 220, 224, 0.60);
                            }
                        }
                    }
                    &:first-of-type {
                        > .events--calendar--body--week--day {
                            &:not(:last-of-type) {
                                border-right: 1px solid rgba(218, 220, 224, 0.60);
                            }
                        }
                    }
                    > .events--calendar--body--week--day {
                        width: calc(calc(100% / 7) - 10px);
                        aspect-ratio: 180 / 185;
                        padding-left: 5px;
                        padding-right: 5px;
                        > .events--calendar--body--week--day--number {
                            width: 100%;
                            box-sizing: border-box;
                            @include header-level5($secondary-sanfrancisco-fog-s3);
                        }
                        > .events--calendar--body--week--day--event {
                            width: 100%;
                            box-sizing: border-box;
                            &.events--calendar--body--week--day--event__visible-details {
                                position: relative;
                            }
                            &.events--calendar--body--week--day--event__community {
                                border-left: 5px solid $event-category-community;
                                > .events--calendar--body--week--day--event--details {
                                    border-bottom-color: $event-category-community;
                                }
                            }
                            &.events--calendar--body--week--day--event__development {
                                border-left: 5px solid $event-category-development;
                                > .events--calendar--body--week--day--event--details {
                                    border-bottom-color: $event-category-development;
                                }
                            }
                            &.events--calendar--body--week--day--event__events {
                                border-left: 5px solid $event-category-events;
                                > .events--calendar--body--week--day--event--details {
                                    border-bottom-color: $event-category-events;
                                }
                            }
                            margin-top: 10px;
                            padding-bottom: 1px;
                            > .events--calendar--body--week--day--event--name {
                                width: 100%;
                                box-sizing: border-box;
                                padding-left: 5px;
                                > a {
                                    @include trim-ellipsis-multiline(2);
                                    overflow: hidden;
                                    color: #000;
                                    text-overflow: ellipsis;
                                    white-space: normal;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 16px;
                                    text-decoration: none;
                                    &:hover,
                                    &:active {
                                        font-weight: 600;
                                    }
                                }
                            }
                            > .events--calendar--body--week--day--event--details {
                                display: none;
                                opacity: 0;
                                transition-property: opacity;
                                transition-duration: 0.5s;
                                transition-timing-function: ease-in;
                                &.events--calendar--body--week--day--event--details__show {
                                    display: block;
                                    opacity: 1;
                                }
                                position: absolute;
                                top: 0;
                                left: 100%;
                                right: auto;
                                bottom: auto;
                                @extend .events--calendar--details--card;
                                padding-top: 0px;
                                height: 400px;
                                > .events--calendar--body--week--day--event--details--close-button {
                                    box-sizing: border-box;
                                    width: 100%;
                                    padding-top: 7px;
                                    height: 30px;
                                    text-align: right;
                                    position: relative;
                                    left: 20px;
                                }
                            }
                        }
                    }
                    &:nth-of-type(4) {
                        > .events--calendar--body--week--day {
                            > .events--calendar--body--week--day--event {
                                > .events--calendar--body--week--day--event--details {
                                    top: -100px;
                                }
                            }
                        }
                    }
                    &:nth-of-type(5) {
                        > .events--calendar--body--week--day {
                            > .events--calendar--body--week--day--event {
                                > .events--calendar--body--week--day--event--details {
                                    top: -200px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    > .events--calendar__list-view {
        > .solutions-card-grid {
            background: unset;
            padding: 0;
            &:not(:first-of-type) {
                margin-top: 100px;
            }
            > h2 {
                @include header-level2($primary-open-sky-s2);
            }
            > .events--calendar__list-view--card-wrapper {
                display: grid;
                @media screen and (min-width: 1460px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    column-gap: 100px;
                    row-gap: 100px;
                }
                @media screen and (min-width: 1401px) and (max-width: 1459px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    column-gap: 25px;
                    row-gap: 25px;
                }
                @media screen and (min-width: 835px) and (max-width: 1400px) {
                    grid-template-columns: 1fr 1fr;
                    column-gap: 50px;
                    row-gap: 50px;
                }
                @media screen and (max-width: 834px) {
                    padding: 0;
                    grid-template-columns: 1fr;
                    row-gap: 50px;
                }
                > .events--calendar--details--card {
                    width: auto;
                    min-width: 350px;
                    @media screen and (max-width: 410px) {
                        min-width: calc(100% - 60px);
                    }
                    &.events--calendar__list-view--event-card__community {
                        border-bottom: $event-category-community 10px solid;
                    }
                    &.events--calendar__list-view--event-card__development {
                        border-bottom: $event-category-development 10px solid;
                    }
                    &.events--calendar__list-view--event-card__events {
                        border-bottom: $event-category-events 10px solid;
                    }
                }
            }
        }
    }
    > .events--calendar--more {
        @extend .conference-speakers--session-speakers--more;
        margin-top: 80px;
    }
}

.full-width-layout--content 
> .full-width-layout--content--body {
    > .events--secondary-content {
        padding: 0;
        > .solutions-card-grid {
            @include page-element-padding;
            > hgroup {
                > h2 {
                    @include header-level2($primary-pacific-blue-s3);
                }
                > p {
                    @include body-text($primary-pacific-blue-s3);
                }
            }
            > .solutions-card-grid--card-wrapper  {
                padding: 0;
                column-gap: 30px;
    
                > .solutions-card-grid--card-wrapper--card {
                    min-height: 150px;
                    align-items: flex-start;
                    > .solutions-card-grid--card-wrapper--card--label__no-icon {
                        color: $primary-pacific-blue-s3;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;
                        letter-spacing: 4.5px;
                        text-transform: uppercase;
                        padding-bottom: 15px;
                    }
                    > .solutions-card-grid--card-wrapper--card--content {
                        @include body-small;
                        color: $secondary-sanfrancisco-fog-s3;
                        > a {
                            color: $primary-pacific-blue;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
        > .landing-page-content__right-panel {
            @include page-element-padding;
            > .landing-page-content__right-panel--content {
                > .landing-page-content__right-panel--content--body {
                    box-sizing: border-box;
                    @media screen and (min-width: $content-with-panel-wide-screen-width) {
                        padding: 0;
                    }
                    @media screen and (max-width: $content-with-panel-reduce-padding-width) {
                        padding: 0;
                    }
                    @media screen and (max-width: $content-with-panel-collapsed-max) {
                        padding: 0;
                    }
                }
                .draft-3-landing-page--button-stack {
                    box-sizing: border-box;
                    @media screen and (max-width: $content-with-panel-collapsed-max)
                    and (min-width: $min-past-iphone-width) {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 2em;
                        padding: 0 0 0 20px;
                        width: calc(100% - 4em);
                    }
                    @media screen and (min-width: $content-with-panel-expanded-min), 
                    screen and (max-width: $max-iphone-width) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        row-gap: 30px;
                    }
                    @media screen and (max-width: $content-with-panel-collapsed-max) {
                        width: 100%;
                    }
                    @media screen and (min-width: $content-with-panel-expanded-min) {
                        padding-top: 40px;
                    }
                    @media screen and (max-width: $max-iphone-width) {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    @media screen and (max-width: 410px) {
                        .redesign-button--wrapper {
                            width: calc(100% - 60px);
                        }
                        .redesign-button--wrapper.redesign-button--wrapper__complex-content > .redesign-button--anchor {
                            width: unset;
                        }
                    }
                }
            }
        }
    }
}
