$accent-dark-half-opacity: rgba(0, 59, 92, 0.5);
$max-hero-item-count: 20;

.hero-with-headline {
    margin: 10px 0;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    @include respond-max(833px) {
        margin: 0;
        padding: 0;
    }

    .hero-with-headline--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include respond-min(834px) {
            width: 100%;
            max-width: 1440px;
        }
        @include respond-max(833px) {
            width: 100%;
        }

        .hero-with-headline--headline {
            > h1 {
                color: black;
                font-size: 2em;
                font-weight: 600;
                line-height: 1.3;
                @include respond-max(833px) {
                    margin: 1em 0 0.25em;
                }
                @include respond-min(834px) {
                    margin: 0.5em 0 0.5em;
                }
            }
        }
        .hero-with-headline--carousel {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .hero-with-headline--carousel--viewport {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
                @include respond-min(835px) {
                    height: 370px;
                }
                @include respond-max(834px) {
                    height: 430px;
                    font-size: 0; // mobile-extra height fix.
                }
                overflow-x: hidden;
                $arrow-button-width-height: 60px;
                $arrow-button-half-height: 30px;
                .landing-page-hero--arrows--arrow {
                    @include respond-max(834px) {
                        display: none;
                    }
                    position: absolute;
                    top: calc(50% - $arrow-button-half-height);
                    margin: auto;
                    height: $arrow-button-width-height;
                    width: $arrow-button-width-height;
                    background: rgba(255, 255, 255, 0.15);
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    box-sizing: border-box;
                    padding-top: 6px;
                    z-index: 2;
                    > opensearch-button {
                        opacity: 0.4;
                        width: $arrow-button-width-height;
                        height: $arrow-button-width-height;
                        transition: opacity 0.25s ease-in-out;
                        &:hover {
                            opacity: 1;
                        }
                    }
                    &.landing-page-hero--arrows--arrow__left {
                        left: 0;
                        svg {
                            position: relative;
                            left: 15px;
                            top: 6px;
                        }
                    }
                    &.landing-page-hero--arrows--arrow__right {
                        left: calc(100% - $arrow-button-width-height);
                        svg {
                            position: relative;
                            left: 0;
                            top: 2px;
                        }
                    }
                }
                .hero-with-headline--carousel--wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    box-sizing: border-box;
                    width: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    align-items: center;
                    &.hero-with-headline--carousel--items-wrapper__wind-back {
                        animation: carousel-animation-wind-back 1s ease-in-out;
                        animation-fill-mode: forwards;
                        animation-delay: 0s;
                        animation-iteration-count: 1;
                        animation-direction: normal;
                        animation-play-state: running;
                        animation-timing-function: ease-in-out;
                    }
                    @for $from-frame-counter from 0 through $max-hero-item-count {
                        @for $to-frame-counter from 0 through $max-hero-item-count {
                            @if $from-frame-counter != $to-frame-counter {
                                &.hero-with-headline--carousel--wrapper__running__from-#{$from-frame-counter}__to-#{$to-frame-counter} {
                                    animation: carousel-animation-from-to-slide-#{$from-frame-counter}-to-#{$to-frame-counter} 1s ease-in-out;
                                    animation-fill-mode: forwards;
                                    animation-delay: 0s;
                                    animation-iteration-count: 1;
                                    animation-direction: normal;
                                    animation-play-state: running;
                                    animation-timing-function: ease-in-out;
                                }
                            }
                        }
                    }
                    .hero-with-headline--carousel--item {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: stretch;
                        justify-content: center;
                        align-self: stretch;
                        flex-grow: 1;
                        flex-shrink: 0;
                        flex-basis: 100%;
                    }
                }
            }
        }
        .hero-with-headline--carousel--pagination {
            display: block;
            width: 100%;
            box-sizing: content-box;
            padding: 5px 0;
            text-align: center;
            .hero-with-headline--carousel--pagination--items-wrapper {
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                column-gap: 10px;
                .hero-with-headline--carousel--pagination--item {
                    flex: 1;
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    margin: 0 2.5px;
                    border-radius: 50%;
                    opacity: 0.5;
                    transition: opacity 0.25s ease-in-out;
                    box-shadow: $accent-dark-half-opacity 0 0 0 2px;
                    $index: 1;
                    cursor: pointer;
                    border-color: $light-theme-hero-control-active;
                    background: $light-theme-hero-control-inactive;
                    &.hero-with-headline--carousel--pagination--item__active {
                        background: $light-theme-hero-control-active;
                    }
                }
            }
        }
    }
}

@for $from-frame-counter from 0 through $max-hero-item-count {
    @for $to-frame-counter from 0 through $max-hero-item-count {
        @if $from-frame-counter != $to-frame-counter {
            @keyframes carousel-animation-from-to-slide-#{$from-frame-counter}-to-#{$to-frame-counter} {
                0% {
                    transform: translateX(calc(-100% * #{$from-frame-counter}));
                }
                100% {
                    transform: translateX(calc(-100% * #{$to-frame-counter}));
                }
            }
        }
    }
}

@keyframes carousel-animation-wind-back {
    0% {
        transform: translateX(-500%);
    }
    33% {
        transform: translateX(-530%);
    }
    100% {
        transform: translateX(0);
    }
}
