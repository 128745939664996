div#carousel-id-herobanner {

}

div.herobanner-container {
}

div.herobanner-container img
{
	width: 100%; 
	border-radius: 10px;
}

