.platform-solution {
    @include respond-max(767px) {
        margin-left: 1em;
        margin-right: 1em;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
}
